import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';

import '../../../styles/Plants.css'
import {useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    padding: 0,

  },
  accordionInnerDiv: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    paddingTop: 20,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    alignSelf: 'start',
    fontSize: '100%',
  },

  iconButton: {
    color: '#323232',
    zIndex: 10001,
  },
  iconButtonAdd: {
    position: 'absolute',
    right: 0,
    top: -18,
    color: '#323232',
    zIndex: 10001,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 30,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '30%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
    marginBottom: 20,
    marginTop: 20,
  },
  checkBox: {
    color: '#168b77',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0,
  },
  cardInner: {
    width: '30%',
    textAlign: 'left',
    padding: 10,
    paddingLeft: 20,
  },
  cardInnerRight: {
    width: '10%',
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

export default function ShopItem(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  useFirestoreConnect({
    collection: `plants/${props.plantItem.plantID}/prices`,
    storeAs: "prices",
  });
  const prices = useSelector((state) => state.firestore.data.prices);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceId, setPriceId] = useState(null);

  const [priceArr, setPriceArr] = useState([]);
  const [plantShop, setPlantShop] = useState(false);

  const [plantItem, setPlantItem] = useState([]);
  const [priceWeight, setPriceWeight] = useState(0);
  const [price, setPrice] = useState(0);
  const [privateCustomer, setPrivateCustomer] = useState(false);
  const [companyCustomer, setCompanyCustomer] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenEdit = (index) => {
    console.log(index)
    setPriceId(index.priceID)
    setPrice(index.price)
    setPriceWeight(index.weight)
    setPrivateCustomer(index.private)
    setCompanyCustomer(index.company)
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setPrice(0)
    setPriceWeight(0)
    setPrivateCustomer(false)
    setCompanyCustomer(false)
    setPriceId(null)
    setLoading(false);
  };

  const changeShopState = () => {
    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .update({
        plantShop: !plantShop,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
        if (!plantShop === true) {
          dispatch(showSuccessSnackbar(props.plantItem.plantName + ' lades till i webshoppen!'));
        } else {
          dispatch(showSuccessSnackbar(props.plantItem.plantName + ' togs bort från webshoppen!'));
        }
      setPlantShop(!plantShop)
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar('Något gick fel. Var god försök igen!'));
    });
  };

  const handleAddPrice = () => {
    setLoading(true)

    let priceObj = {
      price: price,
      weight: priceWeight,
      private: privateCustomer,
      company: companyCustomer,
      author: uid,
      priceStartDate: Date.now(),
      updatedAt: Date.now(),
      updatedBy: uid,
      plantID: props.plantItem.plantID,
      plantName: props.plantItem.plantName,
      plantDisplayName: props.plantItem.plantDisplayName
    }

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('prices')
      .add(priceObj)
      .then((docRef) =>{
        docRef.update({
          priceID: docRef.id,
        });
      })
      .then(() =>{
      setOpen(false);
      setLoading(false)
      dispatch(showSuccessSnackbar('Priset lades till i ' + props.plantItem.plantName));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleUpdatePrice = () => {
    setLoading(true)

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('prices')
      .doc(priceId)
      .update({
        price: price,
        weight: priceWeight,
        private: privateCustomer,
        company: companyCustomer,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      dispatch(showSuccessSnackbar('Priset uppdaterades i ' + props.plantItem.plantName));
      handleCloseEdit();
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleDeletePrice = () => {
    setLoading(true)

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('prices')
      .doc(priceId)
      .delete()
      .then(() =>{
      dispatch(showSuccessSnackbar('Priset raderades från ' + props.plantItem.plantName));
      handleCloseEdit();
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (prices) {
      console.log(props.plantItem)
      console.log(Object.entries(prices))
      setPriceArr(Object.entries(prices))

    } else {
      setPriceArr(null)
    }
  }, [prices, props.plantItem]);

  return (
    <div className='InfoItem'>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Webshop</Typography>
        </AccordionSummary>
          <AccordionDetails className={classes.accordionInner}>
            <div className={classes.switchOuter}>
              <FormControlLabel
                control={
                  <Switch
                    color="default"
                    checked={plantShop}
                    onChange={changeShopState}
                    name="setPlantShop"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={plantShop ? 'Publicerad' : 'Opublicerad'}
              />
            </div>
            {priceArr && priceArr.length > 0 ? (
              <div className={classes.accordionInnerDiv}>
                <IconButton
                  variant="outlined"
                  color="primary"
                  classes={{colorPrimary: classes.iconButtonAdd}}
                  onClick={handleClickOpen}
                >
                  <AddIcon/>
                </IconButton>
                {priceArr.map((priceItem, priceIndex) => {
                  return (
                    <Card
                      className={classes.root}
                      key={priceIndex}
                    >
                      <CardContent className={classes.card}>
                        <div className={classes.cardInner}>
                          <Typography className={classes.titleTextFirst} variant={'caption'}>Vikt:</Typography>
                          <Typography className={classes.infoText} variant={'body1'} paragraph>{priceItem[1].weight} gram</Typography>
                        </div>
                        <div className={classes.cardInner}>
                          <Typography className={classes.titleTextFirst} variant={'caption'}>Pris:</Typography>
                          <Typography className={classes.infoText} variant={'body1'} paragraph>{priceItem[1].price} kr</Typography>
                        </div>
                        <div className={classes.cardInner}>
                          <div className={classes.checkOuter}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{root: classes.checkBox, checked: classes.checkBox}}
                                  checked={priceItem[1].private}
                                  disabled={true}
                                  name="Privat"
                                  color="default"
                                />
                              }
                              label="Privat"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  classes={{root: classes.checkBox, checked: classes.checkBox}}
                                  checked={priceItem[1].company}
                                  disabled={true}
                                  name="Företag"
                                  color="default"
                                />
                              }
                              label="Företag"
                            />
                          </div>
                        </div>
                        <div className={classes.cardInnerRight}>
                          <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={() => handleClickOpenEdit(priceItem[1])}>
                            <MoreVertIcon/>
                          </IconButton>
                        </div>
                      </CardContent>
                    </Card>
                  )
                })}
              </div>
            ) : (
              <Button classes={{root: classes.button}} variant="outlined" onClick={handleClickOpen} color="primary">
                Klicka här för att lägga till priser
              </Button>
              )}
          </AccordionDetails>
      </Accordion>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Lägg till pris:"}</DialogTitle>
        <DialogContent>
          <div className={classes.textFieldOuterSelect}>
            <div className={classes.switchOuter}>
              <ValidationTextField
                id="setPlantDarkDays"
                label="Gram"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data) => setPriceWeight(data.target.value)}
                helperText="Vikt"
                variant="outlined"
              />
            </div>
            <div className={classes.switchOuter}>
              <ValidationTextField
                id="setEstimatedYieldMicro"
                label="Kr"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data) => setPrice(data.target.value)}
                helperText="Pris"
                variant="outlined"
              />
            </div>
            <div className={classes.checkOuter}>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={privateCustomer}
                    onChange={() => setPrivateCustomer(!privateCustomer)}
                    name="Privat"
                    color="default"
                  />
                }
                label="Privat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={companyCustomer}
                    onChange={() => setCompanyCustomer(!companyCustomer)}
                    name="Företag"
                    color="default"
                  />
                }
                label="Företag"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <SplashScreen/>
          ) : (
            <Button onClick={handleAddPrice} color="primary" autoFocus>
              Lägg till
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Uppdatera pris:"}</DialogTitle>
        <DialogContent>
          <div className={classes.textFieldOuterSelect}>
            <div className={classes.switchOuter}>
              <ValidationTextField
                id="setPlantDarkDays"
                label="Gram"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data) => setPriceWeight(data.target.value)}
                helperText="Vikt"
                variant="outlined"
                value={priceWeight}
              />
            </div>
            <div className={classes.switchOuter}>
              <ValidationTextField
                id="setEstimatedYieldMicro"
                label="Kr"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(data) => setPrice(data.target.value)}
                helperText="Pris"
                variant="outlined"
                value={price}
              />
            </div>
            <div className={classes.checkOuter}>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={privateCustomer}
                    onChange={() => setPrivateCustomer(!privateCustomer)}
                    name="Privat"
                    color="default"
                  />
                }
                label="Privat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={companyCustomer}
                    onChange={() => setCompanyCustomer(!companyCustomer)}
                    name="Företag"
                    color="default"
                  />
                }
                label="Företag"
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <SplashScreen/>
          ) : (
            <div>
              <Button onClick={handleDeletePrice} color="primary" autoFocus>
                Radera
              </Button>
              <Button onClick={handleUpdatePrice} color="primary" autoFocus>
                Uppdatera
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
