import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import 'moment/locale/sv'

import '../../../styles/Plants.css'
import {useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';
import EventsBarAlt from './EventsBarAlt'

moment.locale('sv')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 0,
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    padding: 0,

  },
  accordionInnerDiv: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    paddingTop: 20,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    fontSize: '110%',
    margin: 0,
    marginLeft: 3,
  },

  iconButton: {
    color: '#323232',
    zIndex: 10001,
  },
  iconButtonAdd: {
    position: 'absolute',
    right: 0,
    top: -18,
    color: '#323232',
    zIndex: 10001,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 30,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  dateOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '30%',
  },

  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
    marginBottom: 20,
    marginTop: 20,
  },
  checkBox: {
    color: '#168b77',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0,
  },
  cardBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '60%',
    padding: 0,
  },
  cardInner: {
    width: '20%',
    textAlign: 'left',
    padding: 10,
    paddingLeft: 20,
  },
  cardInnerBig: {
    width: '20%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: 20,
  },
  cardInnerDate: {
    width: '70%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  cardInnerDateInner: {
    width: '40%',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },

  cardInnerText: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    width: '100%',
  },
  cardInnerTextSelf: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 20,
  },
  cardInnerInner: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
  },
  cardInnerRight: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: '#168b77',
    marginBottom: 10,
    opacity: 0.3
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
    card: {
      flexDirection: 'column',
    },
    cardInnerBig: {
      width: '90%',
      flexDirection: 'row',
    },
    cardInnerDate: {
      width: '90%',
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

/**
 * @return {null}
 */
export default function PlantEvents(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  let history = useHistory();
  const { uid } = useSelector((state) => state.firebase.auth);

  useFirestoreConnect({
    collection: `plants/${props.plantItem.plantID}/plantItems`,
    storeAs: "plantItems",
  });
  const plantItems = useSelector((state) => state.firestore.data.plantItems);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [plantItem, setPlantItem] = useState();
  const [startDate, setStartDate] = useState(Date.now());
  const [plantCount, setPlantCount] = useState(0);
  const [shelf, setShelf] = useState(0);
  const [plane, setPlane] = useState(0);
  const [section, setSection] = useState(0);

  const [itemsArr, setItemsArr] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const calculateHarvestDate = (startD) => {

    let days;
    switch(props.plantItem.plantCategory) {
      case 'Mikrogrönt':
        days = moment(startD, "YYYY-MM-DD").add(Number(props.plantItem.microSpecifics.plantDays), 'days');
        break;
      case 'Örter/Kryddor':
        days = moment(startD, "YYYY-MM-DD").add(Number(props.props.plantItem.herbSpecifics.daysUntilFirstHarvestHerb), 'days')
        break;
      default:
        days = moment(startD, "YYYY-MM-DD").add(Number(props.plantItem.lettuceSpecifics.daysUntilFirstHarvest), 'days')
    }

    return moment().startOf('day').to(days);
  };

  const handleAddPlantItems = () => {
    setLoading(true)

    let tempId;

    let plantsObj = {
      plantID: props.plantItem.plantID,
      author: uid,
      updatedBy: uid,
      updatedAt: Date.now(),
      startDate: startDate,
      plantCount: plantCount,
      shelf: shelf,
      plane: plane,
      section: section,
      active: true,
    }

    let eventsObj = {
      eventType: 'start',
      name: 'Start',
      plantID: props.plantItem.plantID,
      author: uid,
      updatedAt: Date.now(),
      startDate: startDate,
    }

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('plantItems')
      .add(plantsObj)
      .then((docRef) =>{
        docRef.update({
          itemID: docRef.id,
        });
        tempId = docRef.id
      })
      .then((docRef) =>{
        setOpen(false);
        setLoading(false)
        dispatch(showSuccessSnackbar('Plantorna lades till i ' + props.plantItem.plantName));

      })
      .catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (plantItems) {
      setItemsArr(Object.entries(plantItems))
    } else {
      setItemsArr(null)
    }
  }, [plantItems]);

  return (
      props.plantItem ? (
        <div className='InfoItem'>
          <Accordion className={classes.root}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Plantor</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionInner}>
              {itemsArr && itemsArr.length > 0 ? (
                <div className={classes.accordionInnerDiv}>
                  <IconButton
                    variant="outlined"
                    color="primary"
                    classes={{colorPrimary: classes.iconButtonAdd}}
                    onClick={handleClickOpen}
                  >
                    <AddIcon/>
                  </IconButton>
                  {itemsArr.map((plantInArr, priceIndex) => {
                    return (
                      <Card
                        className={classes.cardRoot}
                        key={priceIndex}
                      >
                        <CardContent className={classes.card}>
                          <div className={classes.cardInnerBig}>
                              <div className={classes.cardInnerText}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>Antal:</Typography>
                                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInArr[1].plantCount}</Typography>
                              </div>
                              <div className={classes.cardInnerText}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>Hylla:</Typography>
                                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInArr[1].shelf}</Typography>
                              </div>
                              <div className={classes.cardInnerText}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>Plan:</Typography>
                                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInArr[1].plane}</Typography>
                              </div>
                              <div className={classes.cardInnerText}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>Sektion:</Typography>
                                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInArr[1].section}</Typography>
                              </div>
                          </div>
                          <div className={classes.cardInnerDate}>
                            <div className={classes.cardInnerDateInner}>
                              <div className={classes.cardInnerTextSelf}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>{moment(Date.now()).diff(moment(plantInArr[1].startDate), 'days')} dagar gammal</Typography>
                              </div>
                              <div className={classes.cardInnerTextSelf}>
                                <Typography className={classes.titleTextFirst} variant={'body1'}>Skördas:</Typography>
                                <Typography className={classes.infoText} variant={'body1'} paragraph>{calculateHarvestDate(plantInArr[1].startDate)}</Typography>
                              </div>
                            </div>
                            <div className={classes.cardBar}>
                              <EventsBarAlt plantItem={props.plantItem} eventItem={plantInArr[1]}/>
                            </div>
                          </div>
                          <div className={classes.cardInnerRight}>
                            <IconButton
                              variant="outlined"
                              color="primary"
                              classes={{colorPrimary: classes.iconButton}}
                              onClick={() => history.push(`/events/${plantInArr[1].itemID}`)}
                            >
                              <MoreVertIcon/>
                            </IconButton>
                          </div>
                        </CardContent>
                        <Divider variant="middle" classes={{middle: classes.divider, root: classes.divider}}/>
                      </Card>
                    )
                  })}
                </div>
              ) : (
                <Button classes={{root: classes.button}} variant="outlined" onClick={handleClickOpen} color="primary">
                  Klicka här för att lägga till plantor
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Lägg till plantor:"}</DialogTitle>
            <DialogContent>
              <div className={classes.textFieldOuterSelect}>
                <div className={classes.switchOuter}>
                  <ValidationTextField
                    id="setPlantDarkDays"
                    label="Antal"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setPlantCount(data.target.value)}
                    helperText={props.plantItem.plantCategory === 'Mikrogrönt' ? ('Fat') : ('Plantor')}
                    variant="outlined"
                  />
                </div>
                <div className={classes.dateOuter}>
                  <ValidationTextField
                    id="date"
                    label="Startdatum"
                    type="date"
                    defaultValue={moment(Date.now()).format('YYYY/MM/DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setStartDate(data.target.value)}
                  />
                </div>

              </div>
              <div className={classes.textFieldOuterSelect}>
                <div className={classes.switchOuter}>
                  <ValidationTextField
                    id="setEstimatedYieldMicro"
                    label="Nr"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setShelf(data.target.value)}
                    helperText="Hylla"
                    variant="outlined"
                  />
                </div>
                <div className={classes.switchOuter}>
                  <ValidationTextField
                    id="setEstimatedYieldMicro"
                    label="Nr"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setPlane(data.target.value)}
                    helperText="Plan"
                    variant="outlined"
                  />
                </div>
                <div className={classes.switchOuter}>
                  <ValidationTextField
                    id="setEstimatedYieldMicro"
                    label="Nr"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setSection(data.target.value)}
                    helperText="Sektion"
                    variant="outlined"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              {loading ? (
                <SplashScreen/>
              ) : (
                <Button onClick={handleAddPlantItems} color="primary" autoFocus>
                  Lägg till
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        null
      )
  );
}
