import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import '../../../styles/Plants.css'
import {useFirestore} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';
import moment from 'moment';
import 'moment/locale/sv'
import DialogContent from "@material-ui/core/DialogContent/DialogContent";

moment.locale('sv')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 20,

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    fontSize: '110%',
    margin: 0,
    marginLeft: 3
  },

  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'grey',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 30,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
  },
  dateOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '30%',
  },
  textOuterDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0,
  },
  textInnerDiv: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    width: '20%',
  },
  dateInnerDiv: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    width: '40%',
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

export default function EventInfo(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [plantItem, setPlantItem] = useState();
  const [eventItem, setEventItem] = useState();
  const [startDate, setStartDate] = useState(Date.now());
  const [plantCount, setPlantCount] = useState(Date.now());
  const [shelf, setShelf] = useState(0);
  const [plane, setPlane] = useState(0);
  const [section, setSection] = useState(0);

  const handleSettingClick = () => {
    setOpenEdit(!openEdit);
  };

  const calculateHarvestDate = (dateProp) => {

    let startD = moment(dateProp)
    console.log(props.eventItem)
    console.log(props.plantItem)
    console.log(plantItem)
    console.log(startD)
    let days;
    switch(plantItem.plantCategory) {
      case 'Mikrogrönt':
        console.log('Mikro:' + plantItem.plantCategory)
        days = moment(startD, "YYYY-MM-DD").add(Number(plantItem.microSpecifics.plantDays), 'days');
        break;
      case 'Örter/Kryddor':
        console.log('Örter/Kryddor:' + plantItem.plantCategory)
        days = moment(startD, "YYYY-MM-DD").add(Number(plantItem.herbSpecifics.daysUntilFirstHarvestHerb), 'days')
        break;
      default:
        console.log('Default:' + plantItem.plantCategory)
        days = moment(startD, "YYYY-MM-DD").add(Number(plantItem.lettuceSpecifics.daysUntilFirstHarvest), 'days')
    }

    console.log(days.format())
    return moment(Date.now()).to(days);
  };

  const handleUpdateInfo = () => {
    setLoading(true)
    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('plantItems')
      .doc(props.eventItem.itemID)
      .update({
        updatedAt: Date.now(),
        updatedBy: uid,
        plantCount: plantCount,
        startDate: startDate,
        shelf: shelf,
        plane: plane,
        section: section,
      }).then(() =>{
      setOpenEdit(!openEdit);
      setLoading(false)
      dispatch(showSuccessSnackbar('Plantorna uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (props.eventItem && props.plantItem) {
      setPlantItem(props.plantItem)
      setEventItem(props.eventItem)
      setStartDate(props.eventItem.startDate)
      setPlantCount(props.eventItem.plantCount)
      setShelf(props.eventItem.shelf)
      setPlane(props.eventItem.plane)
      setSection(props.eventItem.section)
    }
  }, [props.plantItem, props.eventItem]);

  return (
    plantItem && eventItem ? (
      openEdit ? (
        <div className='EventInfo'>
          <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
            <MoreVertIcon/>
          </IconButton>
          <form className={classes.form} noValidate autoComplete="off">
            <div className={classes.textFieldOuterSelect}>
              <div className={classes.switchOuter}>
                <ValidationTextField
                  id="setPlantDarkDays"
                  label="Antal"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setPlantCount(data.target.value)}
                  helperText={plantItem.plantCategory === 'Mikrogrönt' ? ('Fat') : ('Plantor')}
                  variant="outlined"
                  value={plantCount}
                />
              </div>
              <div className={classes.dateOuter}>
                <ValidationTextField
                  id="date"
                  label="Startdatum"
                  type="date"
                  defaultValue={startDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setStartDate(data.target.value)}
                  value={startDate}
                />
              </div>

            </div>
            <div className={classes.textFieldOuterSelect}>
              <div className={classes.switchOuter}>
                <ValidationTextField
                  id="setEstimatedYieldMicro"
                  label="Nr"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setShelf(data.target.value)}
                  helperText="Hylla"
                  variant="outlined"
                  value={shelf}
                />
              </div>
              <div className={classes.switchOuter}>
                <ValidationTextField
                  id="setEstimatedYieldMicro"
                  label="Nr"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setPlane(data.target.value)}
                  helperText="Plan"
                  variant="outlined"
                  value={plane}
                />
              </div>
              <div className={classes.switchOuter}>
                <ValidationTextField
                  id="setEstimatedYieldMicro"
                  label="Nr"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setSection(data.target.value)}
                  helperText="Sektion"
                  variant="outlined"
                  value={section}
                />
              </div>
            </div>
            <div className={classes.textFieldOuter}>
              {loading ? (
                <SplashScreen/>
              ) : (
                <Button classes={{root: classes.button}}variant="outlined" onClick={handleUpdateInfo} color="primary">
                  Spara
                </Button>
              )}
            </div>
          </form>
        </div>
      ) : (
        <div className='EventInfo'>
          <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
            <MoreVertIcon/>
          </IconButton>
          <div className={classes.textOuterDiv}>
            <div className={classes.textInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'caption'}>Antal:  </Typography>
              <Typography className={classes.infoText} variant={'body1'} paragraph>{plantCount}</Typography>
            </div>
            <div className={classes.dateInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'body1'}>Skördas:  </Typography>
              <Typography className={classes.infoText} variant={'body1'} paragraph>{calculateHarvestDate(startDate)}</Typography>
              </div>
            <div className={classes.dateInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'body1'}>{moment(Date.now()).diff(moment(startDate), 'days')} dagar gammal</Typography>
            </div>
          </div>
          <div className={classes.textOuterDiv}>
            <div className={classes.textInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'caption'}>Hylla:  </Typography>
              <Typography className={classes.infoText} variant={'body1'} paragraph>{shelf}</Typography>
            </div>
            <div className={classes.textInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'caption'}>Plan:  </Typography>
              <Typography className={classes.infoText} variant={'body1'} paragraph>{plane}</Typography>
            </div>
            <div className={classes.textInnerDiv}>
              <Typography className={classes.titleTextFirst} variant={'caption'}>Sektion:  </Typography>
              <Typography className={classes.infoText} variant={'body1'} paragraph>{section}</Typography>
            </div>
          </div>
        </div>
      )
    ) : (
      <SplashScreen/>
      )
  );
}
