import * as React from "react";
import { useFirestore } from "react-redux-firebase";
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { useSelector, useDispatch } from "react-redux";
import shortid from 'shortid';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

// And import the necessary css
import '../../styles/Dashboard.css'
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageResize);

export default function ImageUpload(props) {
  const firestore = useFirestore();
  const firebase = useFirebase()
  const storage = firebase.storage().ref();
  console.log(storage);
  const { uid } = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();
  // use a useState hook to maintain our files collection
  const [files, setFiles] = React.useState([])

  const server = {
    // this uploads the image using firebase
    process: (fieldName, file, metadata, load, error, progress, abort) => {
      // create a unique id for the file
      const id = shortid.generate()

      // upload the image to firebase
      const task = storage.child('images/plants/' + props.plant.plantID + '/' + id).put(file, {
        contentType: 'image/jpeg',
      })

      // monitor the task to provide updates to FilePond
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snap => {
          // provide progress updates
          progress(true, snap.bytesTransferred, snap.totalBytes)
        },
        err => {
          // provide errors
          error(err.message)
        },
        () => {
          // the file has been uploaded
          load(id)
          storage
            .child('images/plants/' + props.plant.plantID + '/' + id)
            .getDownloadURL()
            .then(url => {
              // fetch the actual image using the download URL
              // and provide the blob to FilePond using the load callback
              console.log(url);
              props.addImageLink(url)
            })
            .catch(err => {
              error(err.message)
              abort()
            })
        }
      )
    },

    // this loads an already uploaded image to firebase
    load: (source, load, error, progress, abort) => {
      // reset our progress
      progress(true, 0, 1024)
      console.log(source);

      // fetch the download URL from firebase
      storage
        .child('images/plants/' + props.plant.plantID + '/' + source)
        .getDownloadURL()
        .then(url => {
          // fetch the actual image using the download URL
          // and provide the blob to FilePond using the load callback
          let xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function(event) {
            let blob = xhr.response
            load(blob)
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch(err => {
          error(err.message)
          abort()
        })
    },
  }
  console.log(files);
  return (
    <FilePond
      allowImageResize
      imageResizeTargetWidth='700px'
      labelIdle='Dra hit en bild eller <span class="filepond--label-action">klicka här</span>'
      files={files}
      allowMultiple={false}
      maxFiles={1}
      onupdatefiles={fileItems => {
        if (fileItems.length !== 0) {
          setFiles(fileItems.map(fileItem => fileItem.file))
        }
      }}
      server={server} // todo: add custom server functionality using firebase
    />
  )
}
