import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect, useFirestore } from "react-redux-firebase";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import 'moment/locale/sv'

import SplashScreen from '../ui/SplashScreen'
import OrderListItem from './OrderListItem'
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 230,
    marginTop: 10,
    marginBottom: 10,
  },
  titleDiv: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-beteween',
    alignItems: 'center',
    minHeight: 60,
    backgroundColor: '#168b77',
    borderRadius: '3px 3px 0px 0px',
    opacity: 0.9,
    textAlign: 'left'
  },
  titleDivInner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '30%',
    paddingRight: 10,
    textAlign: 'right'
  },
  titleDivTime: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    marginLeft: 10,
    opacity: 1,
    width: '70%'
  },
  dateTitle: {
    color: 'white',
    opacity: 1,
    fontSize: '95%',
  },
  date: {
    color: 'white',
    opacity: 1,
    fontSize: '105%',
  },
}));

const OrderEventListItem = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ordersCollection, setOrdersCollection] = useState(null);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [placeData, setPlaceData] = useState(null);
  const [eventData, setEventData] = useState(null);

  useEffect(() => {
    if (props.orderItems) {

      firestore
        .collection('places')
        .doc(props.orderItems[0].placeID)
        .get()
        .then((data) =>{
          setPlaceData(data.data())
        })
        .then(() =>{
          firestore
            .collection('places')
            .doc(props.orderItems[0].placeID)
            .collection('events')
            .doc(props.orderItems[0].placeEventID)
            .get()
            .then((eventData) =>{
              setEventData(eventData.data())
              setOrdersCollection(props.orderItems)
              setOrdersLoaded(true)
            })
        })
        .catch((err) => {
        console.log(err)
        setOrdersLoaded(true)
        dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
      });
    } else {
      setOrdersLoaded(true)
    }
  }, [props.orderItems]);

  console.log(ordersCollection);
  console.log(placeData);
  console.log(eventData);

  return (
    ordersLoaded ? (
      <Paper classes={{root: classes.root}} variant="outlined">
        <div className={classes.titleDiv} onClick={() => history.push(`/places/${placeData.placeID}`)}>
          <Typography variant="h6" className={classes.title}>
            {placeData.placeName}
          </Typography>
          <div className={classes.titleDivInner}>
            <div className={classes.titleDivTime}>
              <Typography variant="h6" className={classes.dateTitle}>
                {moment(eventData.startDate).format('YYYY MM DD')}
              </Typography>
            </div>
            <div className={classes.titleDivTime}>
              <Typography variant="h6" className={classes.date}>
                {moment(eventData.startDate).format('HH:mm')} - {moment(eventData.endDate).format('HH:mm')}
              </Typography>
            </div>
          </div>
        </div>
        {ordersCollection.map((ordersArray, orderIndex) => {
          return <OrderListItem
                    key={ordersArray.placeEventID + orderIndex}
                    orderItem={ordersArray}
                    placeItem={placeData}
                    eventItem={eventData}
                    />
        })}
      </Paper>
    ) : (
      <SplashScreen/>
    )
  );
};
export default OrderEventListItem;
