import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import SplashScreen from '../ui/SplashScreen'
import AddOrder from './AddOrder'
import ActiveOrders from './ActiveOrders'
import HistoricOrders from './HistoricOrders'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    position: 'fixed',
    bottom: -4,
    right: 0,
    color: 'white',
    zIndex: 10000,
  },
  addPlantButton: {
    color: '#168b77',
  },
  dialogContainer: {
  },
  activeButton: {
    color: '#168b77',
  },
  inActiveButton: {
    color: 'grey',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Orders = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const [openModal, setModal] = useState(false);
  const [ordersCollection, setOrdersCollection] = useState(null);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [ordersActive, setOrdersActive] = useState(true);

  useFirestoreConnect({
    collection: `orders`,
    storeAs: "orders",
  });
  const orders = useSelector((state) => state.firestore.data.orders);

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleButtonGroupActive = () => {
    setOrdersActive(true);
  };

  const handleButtonGroupInActive = () => {
    setOrdersActive(false);
  };

  useEffect(() => {
    if (orders) {
      let tempArr = {}
      let tempArrHistoric = {}
      let ordersArray = Object.entries(orders)

      for (let i = 0; i < ordersArray.length; i++) {
        console.log(Date.parse(ordersArray[i][1].eventEnd));
        if (Date.parse(ordersArray[i][1].eventEnd) > Date.now()) {
          if (tempArr[ordersArray[i][1].placeEventID]) {
            tempArr[ordersArray[i][1].placeEventID].push(ordersArray[i][1])
          } else {
            tempArr[ordersArray[i][1].placeEventID] = []
            tempArr[ordersArray[i][1].placeEventID].push(ordersArray[i][1])
          }
        } else {
          if (tempArrHistoric[ordersArray[i][1].placeEventID]) {
            tempArrHistoric[ordersArray[i][1].placeEventID].push(ordersArray[i][1])
          } else {
            tempArrHistoric[ordersArray[i][1].placeEventID] = []
            tempArrHistoric[ordersArray[i][1].placeEventID].push(ordersArray[i][1])
          }
        }
      }
      let objToSave = {
        active: tempArr,
        historic: tempArrHistoric
      }
      setOrdersCollection(objToSave)
      setOrdersLoaded(true)
    } else {
      setOrdersLoaded(true)
    }
  }, [orders]);
  
  return (
    <div className='Overview'>
      {ordersLoaded ? (
        ordersCollection ? (
          <div className='PlantsInner'>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              <Button
                classes={ordersActive ? {root: classes.activeButton} : {root: classes.inActiveButton}}
                onClick={handleButtonGroupActive}
              >Aktiva</Button>
              <Button
                classes={ordersActive ? {root: classes.inActiveButton} : {root: classes.activeButton}}
                onClick={handleButtonGroupInActive}
              >Historiska</Button>
            </ButtonGroup>
            {ordersActive ? (
              <ActiveOrders orders={ordersCollection.active}/>
            ) : (
              <HistoricOrders orders={ordersCollection.historic}/>
            )}
          </div>
        ) : (
          <Button classes={{text: classes.addPlantButton}} onClick={handleClickOpen}>
            Det finns inga beställningar. Klicka här för att lägga till.
          </Button>
        )
      ) : (
        <SplashScreen/>
      )}

      <Dialog
        fullScreen
        open={openModal}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Lägg till order
            </Typography>
          </Toolbar>
        </AppBar>
        <AddOrder handleClose={handleClose}/>
      </Dialog>
      {openModal ? (
        null
      ) : (
        <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleClickOpen}>
          <AddIcon/>
        </IconButton>
      )}
    </div>
  );
};
export default Orders;
