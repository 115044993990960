import React, {useState} from "react";
import { useFirebase } from "react-redux-firebase";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {

  },
}));

const Stock = () => {
  const firebase = useFirebase();

  const classes = useStyles();

  return (
    <div className='Overview'>
      <p>Lager</p>
    </div>
  );
};
export default Stock;
