export const firebaseConfig = {
  apiKey: "AIzaSyDhYeqOKEvM5R_uoDxoXI8gMvVLGF69Q3Q",
  authDomain: "vert-labs.firebaseapp.com",
  databaseURL: "https://vert-labs.firebaseio.com",
  projectId: "vert-labs",
  storageBucket: "vert-labs.appspot.com",
  messagingSenderId: "1095434872257",
  appId: "1:1095434872257:web:70fd506650f754ed4cbb40",
  measurementId: "G-K3T5846L1R"
};
 export const logRocketConfig = 'nbr71a/vert-dashboard';
