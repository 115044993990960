import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import {useFirebase, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageUpload from '../ImageUpload';
import 'react-slideshow-image/dist/styles.css'

import '../../../styles/Plants.css'
import Logo from "../../../static/logo/VWebLogo.png";
import SplashScreen from '../../ui/SplashScreen'
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  button: {
    minWidth: 130,
    zIndex: theme.zIndex.drawer + 11001,
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    paddingBottom: 30,
  },
  iconButtonLink: {
    position: 'relative',
    right: 0,
    color: '#168b77',
    zIndex: 10000,
  },
  imageRoot: {
    width: '100%',
    maxWidth: 800,
    minHeight: 100,
    minWidth: 250
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

const PlantImage = (props) => {

  const firebase = useFirebase();
  const firestore = useFirestore();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);
  const [addImage, setAddImage] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);

  const [imageLink, setImageLink] = useState('');
  const [imageLinks, setImageLinks] = useState(props.plantItem.imageLinks);
  const [imageFront, setImageFront] = useState(props.plantItem.frontImage);

  const addImageLink = (url) => {

    let tempFront = imageFront;

    let tempArr = [...imageLinks]
    console.log(tempArr);
    if (tempArr.length <= 0) {
      tempFront = url
    }
    tempArr.push(url)
    console.log(imageFront)
    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .update({
        imageLinks: tempArr,
        updatedAt: Date.now(),
        updatedBy: uid,
        frontImage: tempFront,
      }).then(() =>{
      setImageLink('')
      setImageFront(tempFront)
      handleClose()
      dispatch(showSuccessSnackbar("Bilden lades till i " + props.plantItem.plantName));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  }

  const changeFrontImage = (url) => {

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .update({
        updatedAt: Date.now(),
        updatedBy: uid,
        frontImage: imageLinks[imageIndex],
      }).then(() =>{
      setImageFront(url)
      handleClose()
      dispatch(showSuccessSnackbar("Visningsbild uppdaterades till " + props.plantItem.plantName));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });

  };

  const deleteImageLink = () => {

    let tempFront = imageFront;
    let tempArray = [...imageLinks]
    let tempArrayExport = [...imageLinks]

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i] === imageLinks[imageIndex]) {
        if (tempArray[i] === tempFront) {
          tempArrayExport.splice(i, 1)
            if (tempArrayExport.length > 0) {
              tempFront = tempArrayExport[0];
            } else {
              tempFront = '';
            }

        } else {
          tempArrayExport.splice(i, 1)
        }
      }
    }
    firebase.storage().refFromURL(imageLinks[imageIndex])
    .delete()
    .then(function() {
      setImageIndex(0)
      firestore
        .collection('plants')
        .doc(props.plantItem.plantID)
        .update({
          imageLinks: tempArrayExport,
          updatedAt: Date.now(),
          updatedBy: uid,
          frontImage: tempFront,
        }).then(() =>{
        setImageFront(tempFront);
        handleClose()
        dispatch(showSuccessSnackbar("Bild raderades från  " + props.plantItem.plantName));
      });
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.plantItem.imageLinks.length <= 0) {
      setAddImage(true)
    }
  }, [props])

  useEffect(() => {
    setImageLinks(props.plantItem.imageLinks)
  }, [props.plantItem.imageLinks])

  return (
      <div className='PlantImageDiv'>
        {imageLinks.length > 0 ? (
            <Slide
              easing="ease"
              transitionDuration={800}
              indicators={true}
              autoplay={false}
              onChange={(prev, next) => setImageIndex(next)}
            >
              {imageLinks.map((imageItem, imageIndex) => {
                return (
                  <div className="each-slide" key={imageIndex}>
                    <img src={imageItem} alt='Plant Logo' onClick={handleClickOpen} className='PlantImage'/>
                  </div>
                )})
              }
            </Slide>
          ) : (
          <img src={Logo} alt='Logo' className='PlantImageLogo' onClick={handleClickOpen}/>
        )}
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">Bilder</DialogTitle>
          {addImage && imageLinks.length < 4 ? (
            <div>
              <DialogContent>
                <DialogContentText>
                  Lägg till en bild. Max 4 bilder!
                </DialogContentText>
                <div className={classes.imageRoot}>
                  <ImageUpload
                    plant={props.plantItem}
                    addImageLink={addImageLink}
                    />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setAddImage(false)} color="primary" disabled={props.plantItem.imageLinks.length <= 0}>
                  Redigera bild
                </Button>
              </DialogActions>
            </div>
          ) : (
            imageLinks.length > 0 ? (
              <div>
                <DialogContent>
                  <DialogContentText>
                    Använd bild som framsida eller radera.
                  </DialogContentText>
                  <DialogActions>
                    <Button
                      disabled={imageLinks[imageIndex] === imageFront}
                      variant="contained"
                      classes={{root: classes.button}}
                      onClick={changeFrontImage}
                    >
                      Visningsbild
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      classes={{root: classes.button}}
                      onClick={deleteImageLink}
                    >
                      Radera
                    </Button>
                  </DialogActions>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setAddImage(true)} color="primary" disabled={imageLinks.length >= 4}>
                    Lägg till bild
                  </Button>
                </DialogActions>
              </div>
            ) : (
              null
              )
          )}
        </Dialog>
      </div>
  );
};
export default PlantImage;
