import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import '../../../styles/Plants.css'
import {useFirestore} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 20,

  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    alignSelf: 'start',
    fontSize: '100%',
  },

  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'grey',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  pickerOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    marginRight: 8,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '46%',
  },
  selectOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '99%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
    textFieldOuterSelect: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
  },
  specifics: {
    width: '100%'
  },
  textDivOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    textAlign: 'left',
  },
  textDivInner: {
    width: '46%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  '@media screen and (max-width: 400px)': {
    textDivOuter: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    textDivInner: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

const plantCategories = ['Mikrogrönt', 'Sallad', 'Kål', 'Örter/Kryddor'];

export default function InfoItem(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [plantName, setPlantName] = useState('');
  const [plantCategory, setPlantCategory] = useState("Mikrogrönt");

  //Microgreens
  const [plantDaysMax, setPlantDaysMax] = useState(14);
  const [plantDaysMin, setPlantDaysMin] = useState(10);
  const [plantDays, setPlantDays] = useState(12);
  const [plantDarkDays, setPlantDarkDays] = useState(5);
  const [estimatedYieldMicro, setEstimatedYieldMicro] = useState(0);

  //Lettuce & kale
  const [sproutingDays, setSproutingDays] = useState(30);
  const [daysUntilFirstHarvest, setDaysUntilFirstHarvest] = useState(50);
  const [harvestWindow, setHarvestWindow] = useState(7);
  const [estimatedYieldLettuce, setEstimatedYieldLettuce] = useState(0);

  //Herbs
  const [sproutingDaysHerb, setSproutingDaysHerb] = useState(30);
  const [vegDays, setVegDays] = useState(30);
  const [flowerDays, setFlowerDays] = useState(0);
  const [daysUntilFirstHarvestHerb, setDaysUntilFirstHarvestHerb] = useState(50);
  const [harvestWindowHerb, setHarvestWindowHerb] = useState(14);
  const [estimatedYieldHerb, setEstimatedYieldHerb] = useState(0);
  const [plantCutting, setPlantCutting] = useState(false);

  const handleSettingClick = () => {
    setOpenEdit(!openEdit);
  };

  const handleUpdateInfo = () => {
    setLoading(true)
    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .update({
        microSpecifics: {
          plantDays: plantDays,
          plantDaysMin: plantDaysMin,
          plantDaysMax: plantDaysMax,
          plantDarkDays: plantDarkDays,
          estimatedYieldMicro: estimatedYieldMicro,
        },
        lettuceSpecifics: {
          sproutingDays: sproutingDays,
          daysUntilFirstHarvest: daysUntilFirstHarvest,
          harvestWindow: harvestWindow,
          estimatedYieldLettuce: estimatedYieldLettuce,
        },
        herbSpecifics: {
          sproutingDaysHerb: sproutingDaysHerb,
          vegDays: vegDays,
          flowerDays: flowerDays,
          daysUntilFirstHarvestHerb: daysUntilFirstHarvestHerb,
          harvestWindowHerb: harvestWindowHerb,
          estimatedYieldHerb: estimatedYieldHerb,
          plantCutting: plantCutting,
        },
        plantCategory: plantCategory,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      setOpenEdit(!openEdit);
      setLoading(false)
      dispatch(showSuccessSnackbar("Kategori i " + plantName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleCategoryChange = (event) => {
    setPlantCategory(event.target.value);
  };

  useEffect(() => {
    if (props.plantItem) {
      setPlantName(props.plantItem.plantName)
      setPlantCategory(props.plantItem.plantCategory)

      setPlantDaysMax(props.plantItem.microSpecifics.plantDaysMax)
      setPlantDaysMin(props.plantItem.microSpecifics.plantDaysMin)
      setPlantDays(props.plantItem.microSpecifics.plantDays)
      setPlantDarkDays(props.plantItem.microSpecifics.plantDarkDays)
      setEstimatedYieldMicro(props.plantItem.microSpecifics.estimatedYieldMicro)

      setSproutingDays(props.plantItem.lettuceSpecifics.sproutingDays)
      setDaysUntilFirstHarvest(props.plantItem.lettuceSpecifics.daysUntilFirstHarvest)
      setHarvestWindow(props.plantItem.lettuceSpecifics.harvestWindow)
      setEstimatedYieldLettuce(props.plantItem.lettuceSpecifics.estimatedYieldLettuce)

      setSproutingDaysHerb(props.plantItem.herbSpecifics.sproutingDaysHerb)
      setVegDays(props.plantItem.herbSpecifics.vegDays)
      setFlowerDays(props.plantItem.herbSpecifics.flowerDays)
      setDaysUntilFirstHarvestHerb(props.plantItem.herbSpecifics.daysUntilFirstHarvestHerb)
      setHarvestWindowHerb(props.plantItem.herbSpecifics.harvestWindowHerb)
      setEstimatedYieldHerb(props.plantItem.herbSpecifics.estimatedYieldHerb)
      setPlantCutting(props.plantItem.herbSpecifics.plantCutting)



    }
  }, [props.plantItem]);

  return (
    <div className='InfoItem'>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Kategori</Typography>
        </AccordionSummary>
        {openEdit ? (
          <AccordionDetails className={classes.accordionInner} >
            <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
              <MoreVertIcon/>
            </IconButton>
            <form className={classes.form} noValidate autoComplete="off">
              {plantCategory === 'Mikrogrönt' ? (
                <div className={classes.specifics}>
                  <div className={classes.formInner}>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.pickerOuter}>
                        <ValidationTextField
                          id="setPlantDays"
                          value={plantDays}
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setPlantDays(data.target.value)}
                          helperText="Medeltid"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.pickerOuter}>
                        <ValidationTextField
                          id="setPlantDaysMin"
                          value={plantDaysMin}
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setPlantDaysMin(data.target.value)}
                          helperText="Mintid"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.pickerOuter}>
                        <ValidationTextField
                          id="setPlantDaysMax"
                          value={plantDaysMax}
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setPlantDaysMax(data.target.value)}
                          helperText="Maxtid"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setPlantDarkDays"
                          value={plantDarkDays}
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setPlantDarkDays(data.target.value)}
                          helperText="Dagar i mörker"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setEstimatedYieldMicro"
                          value={estimatedYieldMicro}
                          label="Gram"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setEstimatedYieldMicro(data.target.value)}
                          helperText="Estimerad skörd per fat"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                plantCategory === 'Örter/Kryddor' ? (
                  <div className={classes.specifics}>
                    <div className={classes.formInner}>
                      <div className={classes.textFieldOuterSelect}>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setSproutingDaysHerb"
                            value={sproutingDaysHerb}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setSproutingDaysHerb(data.target.value)}
                            helperText="Grotid"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setVegDays"
                            value={vegDays}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setVegDays(data.target.value)}
                            helperText="Estimerad veggtid"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className={classes.textFieldOuterSelect}>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setFlowerDays"
                            value={flowerDays}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setFlowerDays(data.target.value)}
                            helperText="Eventuell blomtid"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setDaysUntilFirstHarvestHerb"
                            value={daysUntilFirstHarvestHerb}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setDaysUntilFirstHarvestHerb(data.target.value)}
                            helperText="Tid till första skörd"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.formInner}>
                      <div className={classes.textFieldOuterSelect}>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setHarvestWindowHerb"
                            value={harvestWindowHerb}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setHarvestWindowHerb(data.target.value)}
                            helperText="Dagar mellan skörd"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setEstimatedYieldHerb"
                            value={estimatedYieldHerb}
                            label="Gram"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setEstimatedYieldHerb(data.target.value)}
                            helperText="Estimerad skörd"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className={classes.textFieldOuter}>
                        <div className={classes.switchOuter}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="default"
                                checked={plantCutting}
                                onChange={() => setPlantCutting(!plantCutting)}
                                name="setPlantCutting"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                            }
                            label={plantCutting ? 'Frö' : 'Stickling'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div  className={classes.specifics}>
                    <div className={classes.formInner}>
                      <div className={classes.textFieldOuterSelect}>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setSproutingDays"
                            value={sproutingDays}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setSproutingDays(data.target.value)}
                            helperText="Grotid"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setDaysUntilFirstHarvest"
                            value={daysUntilFirstHarvest}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setDaysUntilFirstHarvest(data.target.value)}
                            helperText="Tid till första skörd"
                            variant="outlined"
                          />
                        </div>
                      </div>
                      <div className={classes.textFieldOuterSelect}>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setHarvestWindow"
                            value={harvestWindow}
                            label="Dagar"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setHarvestWindow(data.target.value)}
                            helperText="Dagar mellan skörd"
                            variant="outlined"
                          />
                        </div>
                        <div className={classes.switchOuter}>
                          <ValidationTextField
                            id="setEstimatedYieldLettuce"
                            value={estimatedYieldLettuce}
                            label="Gram"
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(data) => setEstimatedYieldLettuce(data.target.value)}
                            helperText="Estimerad skörd"
                            variant="outlined"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className={classes.formInner}>
                <div className={classes.textFieldOuterSelect}>
                  <div className={classes.selectOuter}>
                    <ValidationTextField
                      id="standard-select-category"
                      select
                      label="Välj"
                      value={plantCategory}
                      onChange={handleCategoryChange}
                      helperText="Kategori"
                      fullWidth
                      variant="outlined"
                    >
                      {plantCategories.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </ValidationTextField>
                  </div>
                </div>
                <div className={classes.textFieldOuter}>
                  {loading ? (
                    <SplashScreen/>
                  ) : (
                    <Button classes={{root: classes.button}}variant="outlined" onClick={handleUpdateInfo} color="primary">
                      Spara
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </AccordionDetails>
        ) : (
          plantCategory === 'Mikrogrönt' ? (
            <AccordionDetails className={classes.accordionInner}>
              <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
                <MoreVertIcon/>
              </IconButton>
              <div className={classes.textDivOuter}>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Kategori:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{plantCategory}</Typography>
                </div>
                <div className={classes.textDivInner}>

                </div>
              </div>
              <div className={classes.textDivOuter}>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Medeltid:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{plantDays} dagar</Typography>
                </div>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Mintid:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{plantDaysMin} dagar</Typography>
                </div>
              </div>
              <div className={classes.textDivOuter}>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Maxtid:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{plantDaysMax} dagar</Typography>
                </div>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Dagar i mörker:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{plantDarkDays} dagar</Typography>
                </div>
              </div>
              <div className={classes.textDivOuter}>
                <div className={classes.textDivInner}>
                  <Typography className={classes.titleTextFirst} variant={'caption'}>Estimerad skörd per fat:</Typography>
                  <Typography className={classes.infoText} variant={'body1'} paragraph>{estimatedYieldMicro} gram</Typography>
                </div>
                <div className={classes.textDivInner}>

                </div>
              </div>
            </AccordionDetails>
          ) : (
            plantCategory === 'Örter/Kryddor' ? (
              <AccordionDetails className={classes.accordionInner}>
                <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
                  <MoreVertIcon/>
                </IconButton>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Kategori:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{plantCategory}</Typography>
                  </div>
                  <div className={classes.textDivInner}>

                  </div>
                </div>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Grotid:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{sproutingDaysHerb} dagar</Typography>
                  </div>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Estimerad veggtid:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{vegDays} dagar</Typography>
                  </div>
                </div>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Eventuell blomtid:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{flowerDays} dagar</Typography>
                  </div>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Tid till första skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{daysUntilFirstHarvestHerb} dagar</Typography>
                  </div>
                </div>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Dagar mellan skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{harvestWindowHerb} dagar</Typography>
                  </div>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Estimerad skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{estimatedYieldHerb} gram</Typography>
                  </div>
                </div>
              </AccordionDetails>
            ) : (
              <AccordionDetails className={classes.accordionInner}>
                <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
                  <MoreVertIcon/>
                </IconButton>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Kategori:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{plantCategory}</Typography>
                  </div>
                  <div className={classes.textDivInner}>

                  </div>
                </div>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Grotid:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{sproutingDays} dagar</Typography>
                  </div>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Tid till första skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{daysUntilFirstHarvest} dagar</Typography>
                  </div>
                </div>
                <div className={classes.textDivOuter}>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Dagar mellan skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{harvestWindow} dagar</Typography>
                  </div>
                  <div className={classes.textDivInner}>
                    <Typography className={classes.titleTextFirst} variant={'caption'}>Estimerad skörd:</Typography>
                    <Typography className={classes.infoText} variant={'body1'} paragraph>{estimatedYieldLettuce} gram</Typography>
                  </div>
                </div>
              </AccordionDetails>
            )
          )
        )}
      </Accordion>
    </div>
  );
}
