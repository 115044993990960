import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import SplashScreen from '../ui/SplashScreen'
import OrderEventListItem from './OrderEventListItem'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ActiveOrders = (props) => {
  const firebase = useFirebase();
  const classes = useStyles();

  const [ordersCollection, setOrdersCollection] = useState(null);
  const [ordersLoaded, setOrdersLoaded] = useState(false);

  useEffect(() => {
    if (props.orders) {

      setOrdersCollection(Object.entries(props.orders))
      setOrdersLoaded(true)
    } else {
      setOrdersLoaded(true)
    }
  }, [props.orders]);

  return (
    ordersLoaded ? (
      <div className={classes.root}>
        {ordersCollection.map((ordersArray, orderIndex) => {
          return <OrderEventListItem key={ordersArray[0] + orderIndex} orderItems={ordersArray[1]}/>
        })}
      </div>
    ) : (
      <SplashScreen/>
    )
  );
};
export default ActiveOrders;
