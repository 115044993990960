import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '@material-ui/core/Backdrop';
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

import SplashScreen from '../ui/SplashScreen';

import '../../styles/Dashboard.css'
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  saveButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5
  },
  textFieldOuterImageEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#dcdbe1',
    minHeight: 50,
    borderRadius: 5
  },
  imageStyle: {
    width: '25%'
  },
  divImageStyle: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '80%'
  },
  imageDiv: {
    width: '25%',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#959595',
  },
  imageDivText: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '80%'
  },
  pickerOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    marginRight: 8,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    flexDirection: 'row'
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '50%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
    textFieldOuterSelect: {
      width: '90%',
    },
  },
  specifics: {
    width: '100%'
  },
  iconButtonLink: {
    position: 'relative',
    right: 0,
    color: '#168b77',
    zIndex: 10000,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 11000,
    color: '#fff',
  },
  checkBox: {
    color: '#168b77',
    margin: 0
  },
  backdropButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 20
  },
  button: {
    minWidth: 130,
    zIndex: theme.zIndex.drawer + 11001,
  },
  formControl: {
    margin: 0
  }
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

// Plant settings
const plantCategories = ['Mikrogrönt', 'Sallad', 'Kål', 'Örter/Kryddor'];

const AddDelivery = (props) => {

  const firestore = useFirestore();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector((state) => state.firebase.auth);

  const dateToday = Number(moment().startOf('day'));

  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [placeName, setPlaceName] = useState('');
  const [placeDisplayName, setPlaceDisplayName] = useState('');
  const [placeSubtitle, setPlaceSubtitle] = useState("");
  const [placeInfoOne, setPlaceInfoOne] = useState('');
  const [placeInfoTwo, setPlaceInfoTwo] = useState("");
  const [placeAddressName, setPlaceAddressName] = useState("");
  const [placeStreetAddress, setPlaceStreetAddress] = useState("");
  const [placeCity, setPlaceCity] = useState('');
  const [placePostNumber, setPlacePostNumber] = useState('');
  const [placeCordinates, setPlaceCordinates] = useState('');

  const [privateCustomer, setPrivateCustomer] = useState(false);
  const [companyCustomer, setCompanyCustomer] = useState(false);

  const [placeActive, setPlaceActive] = useState(false);

  const [imageLink, setImageLink] = useState('');
  const [addedImageLink, setAddedImageLink] = useState('');
  const [backdropImage, setBackdropImage] = useState('');

  const addNewPlace = () => {
    setLoading(true)
    firestore
      .collection("places")
      .add({
        placeName: placeName,
        placeDisplayName: placeDisplayName,
        placeSubtitle: placeSubtitle,
        placeInfoOne: placeInfoOne,
        placeInfoTwo: placeInfoTwo,
        placeAddressName: placeAddressName,
        placeStreetAddress: placeStreetAddress,
        placeCity: placeCity,
        placePostNumber: placePostNumber,
        placeCordinates: placeCordinates,
        placeActive: placeActive,
        private: privateCustomer,
        company: companyCustomer,
        imageLink: imageLink,
        updatedAt: Date.now(),
        updatedBy: uid,
        createdAt: Date.now(),
        author: uid,

      })
      .then((docRef) => {
        docRef.update({
          placeID: docRef.id,
        });
      }).then(() => {
      props.handleClose();
      dispatch(showSuccessSnackbar(placeDisplayName + " lades till!"));
    }).catch((error) => {
      setLoading(false)
      console.log(error.message)
      dispatch(showSuccessSnackbar("Något gick fel! Var god försök igen."));
    });
    setPlaceName("");
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen= (image) => {
    setBackdropImage(image)
    setOpenBackdrop(true);
  };

  return (
    loading ? (
      <SplashScreen/>
    ) : (
      <div className='AddPlant'>
        <Button
          className={classes.saveButton}
          autoFocus color="inherit"
          onClick={addNewPlace}
        >
          spara
        </Button>
        <div className={classes.formRoot}>
          <form className={classes.form} noValidate autoComplete="off">
            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantName"
                  label="Namn"
                  variant="outlined"
                  onChange={(data) => setPlaceName(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantDisplayName"
                  label="Visningsnamn"
                  variant="outlined"
                  onChange={(data) => setPlaceDisplayName(data.target.value)}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantSubtitle"
                  label="Undertitel"
                  variant="outlined"
                  onChange={(data) => setPlaceSubtitle(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.switchOuter}>
              <FormControlLabel
                control={
                  <Switch
                    color="default"
                    checked={placeActive}
                    onChange={() => setPlaceActive(!placeActive)}
                    name="setPlantShop"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={placeActive ? 'Publicerad' : 'Opublicerad'}
              />
                <div className={classes.checkOuter}>
                  <FormControlLabel
                    classes={{root: classes.formControl}}
                    control={
                      <Checkbox
                        classes={{root: classes.checkBox, checked: classes.checkBox}}
                        checked={privateCustomer}
                        onChange={() => setPrivateCustomer(!privateCustomer)}
                        name="Privat"
                        color="default"
                      />
                    }
                    label="Privat"
                  />
                  <FormControlLabel
                    classes={{root: classes.formControl}}
                    control={
                      <Checkbox
                        classes={{root: classes.checkBox, checked: classes.checkBox}}
                        checked={companyCustomer}
                        onChange={() => setCompanyCustomer(!companyCustomer)}
                        name="Företag"
                        color="default"
                      />
                    }
                    label="Företag"
                  />
                </div>
            </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantInfoOne"
                  label="Inforuta #1"
                  variant="outlined"
                  onChange={(data) => setPlaceInfoOne(data.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantInfoTwo"
                  label="Inforuta #2"
                  variant="outlined"
                  onChange={(data) => setPlaceInfoTwo(data.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantName"
                  label="Platsnamn"
                  variant="outlined"
                  onChange={(data) => setPlaceAddressName(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantDisplayName"
                  label="Adress"
                  variant="outlined"
                  onChange={(data) => setPlaceStreetAddress(data.target.value)}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantName"
                  label="Stad"
                  variant="outlined"
                  onChange={(data) => setPlaceCity(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantDisplayName"
                  label="GPS-kordinater"
                  variant="outlined"
                  onChange={(data) => setPlaceCordinates(data.target.value)}
                  fullWidth
                />
              </div>
            </div>

          </form>
        </div>
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleBackdropClose}>
          <div>
            <img src={backdropImage} alt='PlantImage'/>
            <div className={classes.backdropButtons}>
              <Button
                variant="contained"
                color="secondary"
                classes={{root: classes.button}}
                onClick={() => setAddedImageLink('')}
              >
                Radera
              </Button>
            </div>
          </div>
        </Backdrop>
      </div>
    )
  );
};
export default AddDelivery;
