import React, {useState} from "react";
import { useFirebase } from "react-redux-firebase";
import { useHistory, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import '../../styles/signin.css'
import Logo from '../../static/logo/VWebLogo.png'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SplashScreen from '../ui/SplashScreen';
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
}));

const SignIn = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth);

  const classes = useStyles();

  const [inputOne, setinputOne] = useState("");
  const [inputTwo, setinputTwo] = useState("");

  const signInWithEmail = () => {
    firebase
      .login({
        email: inputOne,
        password: inputTwo
      })
      .then(() => {
        dispatch(showSuccessSnackbar("Välkommen!"));
        history.push("/");
      });
  };

  return (

    isLoaded(auth) ? (
      isLoaded(auth) && !isEmpty(auth) ? (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      ) : (
        <div className='SignIn'>
          <img src={Logo} alt='Logo' className='Logo'/>
          <div className='SignInForm'>
            <form className={classes.root} noValidate autoComplete="off">
              <TextField
                id="form-email"
                label="Email"
                variant="outlined"
                onChange={(data) => setinputOne(data.target.value)}
              />
              <TextField
                id="form-password"
                label="Lösenord"
                variant="outlined"
                onChange={(data) => setinputTwo(data.target.value)}
              />
            </form>
            <button
              onClick={() => {
                signInWithEmail();
              }}
            >
              Logga in
            </button>
          </div>
        </div>
      )
    ) : (
      <SplashScreen/>
    )
  );
};
export default SignIn;
