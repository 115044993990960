import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
  splash: {
    color: '#168b77',
  },
}));

export default function SplashScreen() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress classes={{colorPrimary: classes.splash}}/>
    </div>
  );
}
