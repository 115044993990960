import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect, useFirestore } from "react-redux-firebase";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import 'moment/locale/sv'

import SplashScreen from '../ui/SplashScreen'
import AddOrder from './AddOrder'
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  rootInner: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  checker: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
  },
  checkerInner: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  nameDiv: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  numberDiv: {
    width: '10%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  priceDiv: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  titleDivTime: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    marginLeft: 10,
    opacity: 1,
    width: '70%'
  },
  dateTitle: {
    color: 'white',
    opacity: 1,
    fontSize: '95%',
  },
  date: {
    color: 'white',
    opacity: 1,
    fontSize: '105%',
  },
  divider: {
    backgroundColor: '#168b77',
    marginBottom: 10,
    opacity: 0.3
  },
  checkBox: {
    color: '#168b77',
    margin: 0
  },
  formControl: {
    margin: 0
  },
}));

const OrderListItem = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [orderItem, setOrderItem] = useState(null);
  const [ordersLoaded, setOrdersLoaded] = useState(false);

  const [orderPaid, setOrderPaid] = useState(false);
  const [orderDelivered, setOrderDelivered] = useState(false);

  const handleUpdatePaid = () => {
    firestore
      .collection('orders')
      .doc(orderItem.orderID)
      .update({
        orderPaid: !orderPaid,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      dispatch(showSuccessSnackbar(orderItem.orderName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleUpdateDelivered = () => {
    firestore
      .collection('orders')
      .doc(orderItem.orderID)
      .update({
        orderDelivered: !orderDelivered,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      dispatch(showSuccessSnackbar(orderItem.orderName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (props.orderItem) {
      setOrderItem(props.orderItem)
      setOrderDelivered(props.orderItem.orderDelivered)
      setOrderPaid(props.orderItem.orderPaid)
      setOrdersLoaded(true)
    } else {
      setOrdersLoaded(true)
    }
  }, [props.orderItem]);

  console.log(orderItem);

  return (
    ordersLoaded ? (
      <Paper classes={{root: classes.root}} variant="outlined">
        <div className={classes.rootInner} >
          <div className={classes.checker}>
            <FormControlLabel
              classes={{root: classes.formControl}}
              control={
                <Checkbox
                  classes={{root: classes.checkBox, checked: classes.checkBox}}
                  checked={orderPaid}
                  onChange={handleUpdatePaid}
                  name="Betald"
                  color="default"
                />
              }
              label="Betald"
            />
            <FormControlLabel
              classes={{root: classes.formControl}}
              control={
                <Checkbox
                  classes={{root: classes.checkBox, checked: classes.checkBox}}
                  checked={orderDelivered}
                  onChange={handleUpdateDelivered}
                  name="Levererad"
                  color="default"
                />
              }
              label="Levererad"
            />
          </div>
          <div className={classes.nameDiv} >
            {orderItem.orderName}
          </div>
          <div className={classes.numberDiv} >
            {orderItem.orderNumber}
          </div>
          <div className={classes.priceDiv} >
            {orderItem.totalPrice} kr
          </div>
        </div>
        <div className={classes.rootInner} >
          {orderItem.orderedItems && orderItem.orderedItems.length > 0 ? (
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Vara</TableCell>
                    <TableCell align="right">Vikt</TableCell>
                    <TableCell align="right">Antal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderItem.orderedItems.map((row, rowIndex) => (
                    <TableRow key={row.priceID}>
                      <TableCell component="th" scope="row">
                        {row.plantDisplayName}
                      </TableCell>
                      <TableCell align="right">{row.weight}g</TableCell>
                      <TableCell align="right">
                        {row.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            null
          )}
        </div>
      </Paper>
    ) : (
      null
    )
  );
};
export default OrderListItem;
