import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import SplashScreen from '../ui/SplashScreen'
import AddDelivery from './AddDelivery'
import DeliveryListItem from './DeliveryListItem'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    position: 'fixed',
    bottom: -4,
    right: 0,
    color: 'white',
    zIndex: 10000,
  },
  addPlantButton: {
    color: '#168b77',
  },
  dialogContainer: {
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Delivery = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const [openModal, setModal] = useState(false);
  const [placesCollection, setPlacesCollection] = useState(null);
  const [placesLoaded, setPlacesLoaded] = useState(false);

  useFirestoreConnect({
    collection: `places`,
    storeAs: "places",
  });
  const places = useSelector((state) => state.firestore.data.places);

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  useEffect(() => {
    console.log(places)
    if (places) {
      let tempArr = []
      let placesArray = Object.entries(places)
      console.log(placesArray)
      for (let i = 0; i < placesArray.length; i++) {
      tempArr.push(placesArray[i][1]);
      }
      setPlacesCollection(tempArr)
      setPlacesLoaded(true)
    } else {
      setPlacesLoaded(true)
    }
  }, [places]);

  return (
    <div className='Overview'>
      {placesLoaded ? (
        placesCollection ? (
          <div className='PlantsInner'>
            {placesCollection.length > 0 ? (
              placesCollection.map((placeItem, placeIndex) => {
                return (
                  <DeliveryListItem
                    placeItem={placeItem}
                    key={placeIndex}
                  />
                )
              })
            ) : (
              null
            )}

          </div>
        ) : (
          <Button classes={{text: classes.addPlantButton}} onClick={handleClickOpen}>
            Det finns inga försäljningsställen. Klicka här för att lägga till.
          </Button>
        )
      ) : (
        <SplashScreen/>
      )}

      <Dialog
        fullScreen
        open={openModal}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Lägg till försäljningsställe
            </Typography>
          </Toolbar>
        </AppBar>
        <AddDelivery handleClose={handleClose}/>
      </Dialog>
      {openModal ? (
        null
      ) : (
        <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleClickOpen}>
          <AddIcon/>
        </IconButton>
      )}
    </div>
  );
};
export default Delivery;
