import React, {useState} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useFirestoreConnect } from "react-redux-firebase";
import {useDispatch} from "react-redux";
import { useFirebase } from "react-redux-firebase";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Overview from '../overview/Overview';
import Plants from '../plants/Plants';
import Orders from '../orders/Orders';
import Tasks from '../tasks/Tasks';
import Stock from '../stock/Stock';
import Delivery from '../delivery/Delivery';

import '../../styles/Dashboard.css'
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: '#168b77',
  },
  indicator: {
    backgroundColor: '#168b77',
  },
  selectedText: {
    color: '#168b77',
  },
}));

const Dashboard = () => {

  const classes = useStyles();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = useState(history.location.pathname)
  const { displayName, uid } = useSelector((state) => state.firebase.auth);

  const handleCallToRouter = (event, tempValue) => {
    history.push(tempValue);
    setValue(tempValue)
  }
  return (
    <div className='Dashboard'>
      <AppBar position="fixed" color='inherit'>
        <Tabs
          value={history.location.pathname}
          onChange={handleCallToRouter}
          aria-label="simple tabs example"
          variant="scrollable"
          classes={{
            indicator: classes.indicator
          }}>
        >
          <Tab value={'/'} classes={{selected: classes.selectedText}} label="Överblick" {...a11yProps(0)} />
          <Tab value={'/plants'} classes={{selected: classes.selectedText}} label="Växter" {...a11yProps(1)} />
          <Tab value={'/orders'} classes={{selected: classes.selectedText}} label="Beställningar" {...a11yProps(2)} />
          <Tab value={'/tasks'} classes={{selected: classes.selectedText}} label="Uppgifter" {...a11yProps(3)} />
          <Tab value={'/stock'} classes={{selected: classes.selectedText}} label="Lager" {...a11yProps(4)} />
          <Tab value={'/places'} classes={{selected: classes.selectedText}} label="Utlämningsplatser" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={history.location.pathname} index={'/'}>
        <Overview/>
      </TabPanel>
      <TabPanel value={history.location.pathname} index={'/plants'}>
        <Plants/>
      </TabPanel>
      <TabPanel value={history.location.pathname} index={'/orders'}>
        <Orders/>
      </TabPanel>
      <TabPanel value={history.location.pathname} index={'/tasks'}>
        <Tasks/>
      </TabPanel>
      <TabPanel value={history.location.pathname} index={'/stock'}>
        <Stock/>
      </TabPanel>
      <TabPanel value={history.location.pathname} index={'/places'}>
        <Delivery/>
      </TabPanel>
      <AppBar position="fixed" color="primary" classes={{colorPrimary: classes.appBar}}>
          <Button color="inherit" onClick={() => {
            firebase.logout().then(() => dispatch(showSuccessSnackbar("Välkommen åter!")))
          }}>Logga ut</Button>
      </AppBar>
    </div>
  );
};
export default Dashboard;
