export const showSuccessSnackbar = message => {
  console.log('Snackbar Open!')
  return { type: "SNACKBAR_SUCCESS", message };

};

export const clearSnackbar = () => {
  console.log('Snackbar Closed!')
  return{ type: "SNACKBAR_CLEAR" };

};
