import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { Slide } from 'react-slideshow-image';
import {useFirebase, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ImageUploadPlaces from './ImageUploadPlaces';
import 'react-slideshow-image/dist/styles.css'

import '../../../styles/Plants.css'
import Logo from "../../../static/logo/VWebLogo.png";
import SplashScreen from '../../ui/SplashScreen'
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  button: {
    minWidth: 130,
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    paddingBottom: 30,
  },
  iconButtonLink: {
    position: 'relative',
    right: 0,
    color: '#168b77',
    zIndex: 10000,
  },
  imageRoot: {
    width: '100%',
    maxWidth: 800,
    minHeight: 100,
    minWidth: 250
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

const PlaceImage = (props) => {

  const firebase = useFirebase();
  const firestore = useFirestore();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);
  const [addImage, setAddImage] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [imageLink, setImageLink] = useState('');

  const addImageLink = (url) => {

    firestore
      .collection('places')
      .doc(props.placeItem.placeID)
      .update({
        imageLink: url,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      handleClose()
      setImageLink(url)
      dispatch(showSuccessSnackbar("Bilden lades till i " + props.placeItem.placeName));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  }

  const deleteImageLink = () => {

    firebase.storage().refFromURL(imageLink)
    .delete()
    .then(function() {
      firestore
        .collection('places')
        .doc(props.placeItem.placeID)
        .update({
          imageLink: '',
          updatedAt: Date.now(),
          updatedBy: uid,
        }).then(() =>{
        setImageLink('')
        handleClose()
        dispatch(showSuccessSnackbar("Bild raderades från  " + props.placeItem.placeName));
      })
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });


    firestore
      .collection('places')
      .doc(props.placeItem.placeID)
      .update({
        imageLink: '',
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      setImageLink('')
      handleClose()
      dispatch(showSuccessSnackbar("Bild raderades från  " + props.placeItem.placeName));
    }).catch((err) => {
      console.log(err)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (props.placeItem.imageLink === '') {
      setAddImage(true)
    }
  }, [props])

  useEffect(() => {
    setImageLink(props.placeItem.imageLink)
  }, [props.placeItem.imageLink])

  return (
    <div className='PlantImageDiv'>
      {imageLink !== '' ? (
        <div className="each-slide" >
          <img src={imageLink} alt='Place Logo' onClick={handleClickOpen} className='PlantImage'/>
        </div>
      ) : (
        <img src={Logo} alt='Logo' className='PlantImageLogo' onClick={handleClickOpen}/>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Bild</DialogTitle>
        {addImage && imageLink === '' ? (
          <div>
            <DialogContent>
              <DialogContentText>
                Lägg till en bild!
              </DialogContentText>
              <div className={classes.imageRoot}>
                <ImageUploadPlaces
                  place={props.placeItem}
                  addImageLink={addImageLink}
                  />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAddImage(false)} color="primary">
                Redigera bild
              </Button>
            </DialogActions>
          </div>
        ) : (
          imageLink !== '' ? (
            <div>
              <DialogContent>
                <DialogContentText>
                  Radera bild
                </DialogContentText>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    classes={{root: classes.button}}
                    onClick={deleteImageLink}
                  >
                    Radera
                  </Button>
                </DialogActions>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setAddImage(true)} color="primary" disabled={imageLink !== ''}>
                  Lägg till bild
                </Button>
              </DialogActions>
            </div>
          ) : (
            <DialogActions>
              <Button onClick={() => setAddImage(true)} color="primary">
                Lägg till bild
              </Button>
            </DialogActions>
          )
        )}
      </Dialog>
    </div>
  );
};
export default PlaceImage;
