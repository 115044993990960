import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";
import {clearSnackbar} from "../../utils/actions/SnackbarActions";

const useStyles = makeStyles((theme) => ({
  root: {

  },
}));

const SuccessBar = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { successSnackbarMessage, successSnackbarOpen } = useSelector(
    state => state.successBar
  );

  console.log(successSnackbarMessage)
  console.log(successSnackbarOpen)
  const handleClose = () => {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
      open={successSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={successSnackbarMessage}
    />
  );
}
export default SuccessBar;
