import React, { useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector, useDispatch } from "react-redux";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '@material-ui/core/Backdrop';
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

import SplashScreen from '../ui/SplashScreen';

import '../../styles/Dashboard.css'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  saveButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5
  },
  textFieldOuterImageEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#dcdbe1',
    minHeight: 50,
    borderRadius: 5
  },
  imageStyle: {
    width: '25%'
  },
  divImageStyle: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '80%'
  },
  imageDiv: {
    width: '25%',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#959595',
  },
  imageDivText: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '80%'
  },
  pickerOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    marginRight: 8,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '46%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
    textFieldOuterSelect: {
      width: '90%',
    },
  },
  specifics: {
    width: '100%'
  },
  iconButtonLink: {
    position: 'relative',
    right: 0,
    color: '#168b77',
    zIndex: 10000,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 11000,
    color: '#fff',
  },
  backdropButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 20
  },
  button: {
    minWidth: 130,
    zIndex: theme.zIndex.drawer + 11001,
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

// Plant settings
const plantCategories = ['Mikrogrönt', 'Sallad', 'Kål', 'Örter/Kryddor'];

const AddPlant = (props) => {

  const dateToday = Number(moment().startOf('day'));

  const [loading, setLoading] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [plantName, setPlantName] = useState('');
  const [plantLatin, setPlantLatin] = useState("");
  const [plantDisplayName, setPlantDisplayName] = useState('');
  const [plantSubtitle, setPlantSubtitle] = useState("");
  const [plantInfoOne, setPlantInfoOne] = useState('');
  const [plantInfoTwo, setPlantInfoTwo] = useState("");
  const [plantManufacturer, setPlantManufacturer] = useState("");
  const [plantStartDate, setPlantStartDate] = useState(dateToday);

  const [plantShop, setPlantShop] = useState(false);

  const [plantCategory, setPlantCategory] = useState("Mikrogrönt");

  //Microgreens
  const [plantDaysMax, setPlantDaysMax] = useState(14);
  const [plantDaysMin, setPlantDaysMin] = useState(10);
  const [plantDays, setPlantDays] = useState(12);
  const [plantDarkDays, setPlantDarkDays] = useState(5);
  const [estimatedYieldMicro, setEstimatedYieldMicro] = useState(0);

  //Lettuce & kale
  const [sproutingDays, setSproutingDays] = useState(30);
  const [daysUntilFirstHarvest, setDaysUntilFirstHarvest] = useState(50);
  const [harvestWindow, setHarvestWindow] = useState(7);
  const [estimatedYieldLettuce, setEstimatedYieldLettuce] = useState(0);

  //Herbs
  const [sproutingDaysHerb, setSproutingDaysHerb] = useState(30);
  const [vegDays, setVegDays] = useState(30);
  const [flowerDays, setFlowerDays] = useState(0);
  const [daysUntilFirstHarvestHerb, setDaysUntilFirstHarvestHerb] = useState(50);
  const [harvestWindowHerb, setHarvestWindowHerb] = useState(14);
  const [estimatedYieldHerb, setEstimatedYieldHerb] = useState(0);
  const [plantCutting, setPlantCutting] = useState(false);

  const [imageLink, setImageLink] = useState('');
  const [imageFront, setImageFront] = useState('');
  const [imageLinks, setImageLinks] = useState([]);
  const [backdropImage, setBackdropImage] = useState('');

  const firestore = useFirestore();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector((state) => state.firebase.auth);

  const handleCategoryChange = (event) => {
    setPlantCategory(event.target.value);
  };

  const addNewPlant = () => {
    setLoading(true)
    firestore
      .collection("plants")
      .add({
        plantName: plantName,
        plantStartDate: plantStartDate,
        plantLatin: plantLatin,
        plantDisplayName: plantDisplayName,
        plantSubtitle: plantSubtitle,
        plantInfoOne: plantInfoOne,
        plantInfoTwo: plantInfoTwo,
        plantCategory: plantCategory,
        plantManufacturer: plantManufacturer,
        plantShop: plantShop,
        microSpecifics: {
          plantDays: plantDays,
          plantDaysMin: plantDaysMin,
          plantDaysMax: plantDaysMax,
          plantDarkDays: plantDarkDays,
          estimatedYieldMicro: estimatedYieldMicro,
        },
        lettuceSpecifics: {
          sproutingDays: sproutingDays,
          daysUntilFirstHarvest: daysUntilFirstHarvest,
          harvestWindow: harvestWindow,
          estimatedYieldLettuce: estimatedYieldLettuce,
        },
        herbSpecifics: {
          sproutingDaysHerb: sproutingDaysHerb,
          vegDays: vegDays,
          flowerDays: flowerDays,
          daysUntilFirstHarvestHerb: daysUntilFirstHarvestHerb,
          harvestWindowHerb: harvestWindowHerb,
          estimatedYieldHerb: estimatedYieldHerb,
          plantCutting: plantCutting,
        },
        imageLinks: imageLinks,
        frontImage: imageFront,
        updatedAt: Date.now(),
        updatedBy: uid,
        author: uid,
        plantActive: true,

      })
      .then((docRef) => {
        docRef.update({
          plantID: docRef.id,
        });
      }).then(() => {
        props.handleClose();
        dispatch(showSuccessSnackbar(plantName + " lades till!"));
    }).catch((error) => {
      setLoading(false)
      console.log(error.message)
      dispatch(showSuccessSnackbar("Något gick fel! Var god försök igen."));
    });
    setPlantName("");
  };

  const addImageLink = () => {

    let imageObj = {
      imageUrl: imageLink,
      front: false,
    }
    let tempArr = imageLinks;

    if (tempArr.length > 3) {
      dispatch(showSuccessSnackbar("Det går inte att lägga till mer än 4 länkar!!"));
      setImageLink('');
    } else {
      if (tempArr.length <= 0) {
        imageObj.front = true;
        setImageFront(imageObj.imageUrl)
      }
      tempArr.push(imageObj)
      setImageLinks(tempArr)
      setImageLink('')
    }
  };

  const deleteImageLink = () => {

    let tempArray = imageLinks;
    let tempArrayExport = imageLinks;

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].imageUrl === backdropImage) {
        if (tempArray[i].front === true) {
          tempArrayExport.splice(i, 1)
          if (tempArrayExport.length > 0) {
            tempArrayExport[0].front = true;
            setImageFront(tempArrayExport[0].imageUrl);
          }
        } else {
          tempArrayExport.splice(i, 1)
        }
      }
    }
    setImageLinks(tempArrayExport);
  };

  const changeFrontImage = () => {

    let tempArray = imageLinks;

    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i].front = tempArray[i].imageUrl === backdropImage;
    }
    setImageLinks(tempArray)
    setImageFront(backdropImage)
  };

  const handleBackdropClose = () => {
    setOpenBackdrop(false);
  };
  const handleBackdropOpen= (image) => {
    setBackdropImage(image)
    setOpenBackdrop(true);
  };

  return (
    loading ? (
      <SplashScreen/>
    ) : (
      <div className='AddPlant'>
        <Button
          className={classes.saveButton}
          autoFocus color="inherit"
          onClick={addNewPlant}
        >
          spara
        </Button>
        <div className={classes.formRoot}>
          <form className={classes.form} noValidate autoComplete="off">
            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantName"
                  label="Namn"
                  variant="outlined"
                  onChange={(data) => setPlantName(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantDisplayName"
                  label="Visningsnamn"
                  variant="outlined"
                  onChange={(data) => setPlantDisplayName(data.target.value)}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantLatin"
                  label="Latinskt namn"
                  variant="outlined"
                  onChange={(data) => setPlantLatin(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantSubtitle"
                  label="Undertitel"
                  variant="outlined"
                  onChange={(data) => setPlantSubtitle(data.target.value)}
                  fullWidth
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantInfoOne"
                  label="Inforuta #1"
                  variant="outlined"
                  onChange={(data) => setPlantInfoOne(data.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantInfoTwo"
                  label="Inforuta #2"
                  variant="outlined"
                  onChange={(data) => setPlantInfoTwo(data.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </div>
            </div>

            <div className={classes.formInner}>
              <div className={classes.textFieldOuter}>
                <ValidationTextField
                  id="setPlantManufacturer"
                  label="Tillverkare"
                  variant="outlined"
                  onChange={(data) => setPlantManufacturer(data.target.value)}
                  fullWidth
                />
              </div>
              <div className={classes.textFieldOuterSelect}>
                <div className={classes.switchOuter}>
                  <ValidationTextField
                    id="standard-select-category"
                    select
                    label="Välj"
                    value={plantCategory}
                    onChange={handleCategoryChange}
                    helperText="Kategori"
                    fullWidth
                    variant="outlined"
                  >
                    {plantCategories.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </ValidationTextField>
                </div>
                <div className={classes.switchOuter}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="default"
                        checked={plantShop}
                        onChange={() => setPlantShop(!plantShop)}
                        name="setPlantShop"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={plantShop ? 'Publicerad' : 'Opublicerad'}
                  />
                </div>
              </div>
            </div>

            {plantCategory === 'Mikrogrönt' ? (
              <div className={classes.specifics}>
                <div className={classes.formInner}>
                  <div className={classes.textFieldOuterSelect}>
                    <div className={classes.pickerOuter}>
                      <ValidationTextField
                        id="setPlantDays"
                        label="Dagar"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setPlantDays(data.target.value)}
                        helperText="Medeltid"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.pickerOuter}>
                      <ValidationTextField
                        id="setPlantDaysMin"
                        label="Dagar"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setPlantDaysMin(data.target.value)}
                        helperText="Mintid"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.pickerOuter}>
                      <ValidationTextField
                        id="setPlantDaysMax"
                        label="Dagar"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setPlantDaysMax(data.target.value)}
                        helperText="Maxtid"
                        variant="outlined"
                      />
                    </div>
                  </div>
                  <div className={classes.textFieldOuterSelect}>
                    <div className={classes.switchOuter}>
                      <ValidationTextField
                        id="setPlantDarkDays"
                        label="Dagar"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setPlantDarkDays(data.target.value)}
                        helperText="Dagar i mörker"
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.switchOuter}>
                      <ValidationTextField
                        id="setEstimatedYieldMicro"
                        label="Gram"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setEstimatedYieldMicro(data.target.value)}
                        helperText="Estimerad skörd per fat"
                        variant="outlined"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              plantCategory === 'Örter/Kryddor' ? (
                <div className={classes.specifics}>
                  <div className={classes.formInner}>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setSproutingDaysHerb"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setSproutingDaysHerb(data.target.value)}
                          helperText="Grotid"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setVegDays"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setVegDays(data.target.value)}
                          helperText="Estimerad veggtid"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setFlowerDays"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setFlowerDays(data.target.value)}
                          helperText="Eventuell blomtid"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setDaysUntilFirstHarvestHerb"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setDaysUntilFirstHarvestHerb(data.target.value)}
                          helperText="Tid till första skörd"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.formInner}>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setHarvestWindowHerb"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setHarvestWindowHerb(data.target.value)}
                          helperText="Dagar mellan skörd"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setEstimatedYieldHerb"
                          label="Gram"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setEstimatedYieldHerb(data.target.value)}
                          helperText="Estimerad skörd"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={classes.textFieldOuter}>
                      <div className={classes.switchOuter}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="default"
                              checked={plantCutting}
                              onChange={() => setPlantCutting(!plantCutting)}
                              name="setPlantCutting"
                              inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                          }
                          label={plantCutting ? 'Frö' : 'Stickling'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div  className={classes.specifics}>
                  <div className={classes.formInner}>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setSproutingDays"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setSproutingDays(data.target.value)}
                          helperText="Grotid"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setDaysUntilFirstHarvest"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setDaysUntilFirstHarvest(data.target.value)}
                          helperText="Tid till första skörd"
                          variant="outlined"
                        />
                      </div>
                    </div>
                    <div className={classes.textFieldOuterSelect}>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setHarvestWindow"
                          label="Dagar"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setHarvestWindow(data.target.value)}
                          helperText="Dagar mellan skörd"
                          variant="outlined"
                        />
                      </div>
                      <div className={classes.switchOuter}>
                        <ValidationTextField
                          id="setEstimatedYieldLettuce"
                          label="Gram"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(data) => setEstimatedYieldLettuce(data.target.value)}
                          helperText="Estimerad skörd"
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

          </form>
        </div>
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleBackdropClose}>
          <div>
            <img src={backdropImage} alt='PlantImage'/>
            <div className={classes.backdropButtons}>
              <Button
                disabled={backdropImage === imageFront}
                variant="contained"
                classes={{root: classes.button}}
                onClick={changeFrontImage}
              >
                Visningsbild
              </Button>
              <Button
                variant="contained"
                color="secondary"
                classes={{root: classes.button}}
                onClick={deleteImageLink}
              >
                Radera
              </Button>
            </div>
          </div>
        </Backdrop>
      </div>
      )
  );
};
export default AddPlant;
