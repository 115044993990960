import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import 'moment/locale/sv'

import '../../../styles/Plants.css'
import {useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';
import MenuItem from "@material-ui/core/MenuItem";

moment.locale('sv')

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    padding: 0,

  },
  accordionInnerDiv: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    padding: 0,
    paddingTop: 20,
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    fontSize: '110%',
    margin: 0,
    marginLeft: 3,
  },

  iconButton: {
    color: '#323232',
    zIndex: 10001,
  },
  iconButtonAdd: {
    position: 'absolute',
    right: 0,
    top: -18,
    color: '#323232',
    zIndex: 10001,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minWidth: 320,
    paddingBottom: 30,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    minWidth: 100,
  },
  dateOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    minWidth: 100,
    marginLeft: 10
  },
  yieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '15%',
    minWidth: 70,
    marginLeft: 10
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '30%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
    marginBottom: 20,
    marginTop: 20,
  },
  checkBox: {
    color: '#168b77',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0,
  },
  cardInner: {
    width: '30%',
    textAlign: 'left',
    padding: 10,
    paddingLeft: 20,
  },
  cardInnerBig: {
    width: '60%',
    textAlign: 'left',
    padding: 10,
    paddingLeft: 20,
  },
  cardInnerText: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
    width: '30%',
  },
  cardInnerTextSelf: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  cardInnerInner: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
  },
  cardInnerRight: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

const eventTypesMicro = [

  {
    eventType: 'dark',
    name: 'Mörker',
  },
  {
    eventType: 'light',
    name: 'Ljus',
  },
  {
    eventType: 'harvest',
    name: 'Skörd',
  },
];

const eventTypesLettuce = [

  {
    eventType: 'sprout',
    name: 'Gro',
  },
  {
    eventType: 'light',
    name: 'Ljus',
  },
  {
    eventType: 'harvest',
    name: 'Skörd',
  },
];

/**
 * @return {null}
 */
export default function PlantEvents(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  let history = useHistory();
  const { uid } = useSelector((state) => state.firebase.auth);

  useFirestoreConnect({
    collection: `plants/${props.plantItem.plantID}/plantItems/${props.plantEvent.itemID}/events`,
    storeAs: "events",
  });
  const events = useSelector((state) => state.firestore.data.events);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState('');

  const [eventType, setEventType] = useState('');
  const [eventYield, setEventYield] = useState(0);

  const [eventID, setEventID] = useState('');

  const [eventsArr, setEventsArr] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenEdit = (eventProp) => {
    console.log(eventProp)
    setStartDate(eventProp[1].startDate);
    setEventType(eventProp[1].eventType)
    setEventID(eventProp[1].eventID)
    setEventYield(eventProp[1].eventYield)
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setLoading(false)
  };

  const handleAddEvent = () => {
    setLoading(true)

    let eventsObj = {
      eventType: eventType,
      eventYield: eventYield,
      plantID: props.plantItem.plantID,
      itemID: props.plantEvent.itemID,
      author: uid,
      updatedAt: Date.now(),
      updatedBy: uid,
      startDate: startDate,
    }

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('plantItems')
      .doc(props.plantEvent.itemID)
      .collection('events')
      .add(eventsObj)
      .then((docRef) =>{
        docRef.update({
          eventID: docRef.id,
        });
      })
      .then((docRef) =>{
        setOpen(false);
        setLoading(false)
        dispatch(showSuccessSnackbar('Händelsen lades till i ' + props.plantItem.plantName));
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
      });
  };

  const handleUpdateEvent = () => {
    setLoading(true)

    let eventsObj = {
      eventType: eventType,
      eventYield: eventYield,
      updatedAt: Date.now(),
      updatedBy: uid,
      startDate: startDate
    }

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('plantItems')
      .doc(props.plantEvent.itemID)
      .collection('events')
      .doc(eventID)
      .update(eventsObj)
      .then(() =>{
        handleCloseEdit();
        dispatch(showSuccessSnackbar('Händelsen uppdaterades i ' + props.plantItem.plantName));
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
      });
  };

  const handleDeleteEvent = () => {
    setLoading(true)

    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .collection('plantItems')
      .doc(props.plantEvent.itemID)
      .collection('events')
      .doc(eventID)
      .delete()
      .then(() =>{
        handleCloseEdit();
        dispatch(showSuccessSnackbar('Händelsen raderades från ' + props.plantItem.plantName));
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
      });
  };

  const handleCategoryChange = (event) => {
    setEventType(event.target.value);
  };

  useEffect(() => {
    if (events) {
      setEventsArr(Object.entries(events))
    } else {
      setEventsArr(null)
    }
  }, [events]);

  return (
    props.plantItem && props.plantEvent ? (
      <div className='InfoItem'>
        <Accordion className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Händelser</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionInner}>
            {eventsArr && eventsArr.length > 0 ? (
              <div className={classes.accordionInnerDiv}>
                <IconButton
                  variant="outlined"
                  color="primary"
                  classes={{colorPrimary: classes.iconButtonAdd}}
                  onClick={handleClickOpen}
                >
                  <AddIcon/>
                </IconButton>
                {eventsArr.map((eventsInArr, eventIndex) => {
                  return (
                    <Card
                      className={classes.root}
                      key={eventIndex}
                    >
                      <CardContent className={classes.card}>
                        <div className={classes.cardInner}>
                          <Typography className={classes.titleTextFirst} variant={'body1'}>Typ:</Typography>
                          <Typography className={classes.infoText} variant={'body1'} paragraph>{eventsInArr[1].eventType.name}</Typography>

                        </div>
                        <div className={classes.cardInner}>
                          <Typography className={classes.titleTextFirst} variant={'body1'}>{moment(Date.now()).diff(moment(eventsInArr[1].startDate), 'days')} dagar gammal</Typography>
                        </div>
                        <div className={classes.cardInner}>
                          <Typography className={classes.titleTextFirst} variant={'body1'}>{moment(Date.now()).diff(moment(eventsInArr[1].startDate), 'days')} dagar gammal</Typography>
                        </div>
                        <div className={classes.cardInnerRight}>
                          <IconButton
                            variant="outlined"
                            color="primary"
                            classes={{colorPrimary: classes.iconButton}}
                            onClick={() => handleClickOpenEdit(eventsInArr)}
                          >
                            <MoreVertIcon/>
                          </IconButton>
                        </div>
                      </CardContent>
                    </Card>
                  )
                })}
              </div>
            ) : (
              <Button classes={{root: classes.button}} variant="outlined" onClick={handleClickOpen} color="primary">
                Klicka här för att lägga till händelse
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Lägg till händelse:"}</DialogTitle>
          <DialogContent>
              <div className={classes.textFieldOuterSelect}>
                <div className={classes.switchOuter}>
                  {props.plantItem.plantCategory === 'Mikrogrönt' ? (
                    <ValidationTextField
                      id="standard-select-category"
                      select
                      label="Välj"
                      value={eventType}
                      onChange={handleCategoryChange}
                      helperText="Kategori"
                      fullWidth
                      variant="outlined"
                    >
                      {eventTypesMicro.map((option) => (
                        <MenuItem key={option.name} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </ValidationTextField>
                  ) : (
                      <ValidationTextField
                        id="standard-select-category"
                        select
                        label="Välj"
                        value={eventType}
                        onChange={handleCategoryChange}
                        helperText="Kategori"
                        fullWidth
                        variant="outlined"
                      >
                        {eventTypesLettuce.map((option) => (
                          <MenuItem key={option.eventType} value={option}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </ValidationTextField>
                    )}
                </div>
                <div className={classes.dateOuter}>
                  <ValidationTextField
                    id="date"
                    label="Startdatum"
                    type="date"
                    defaultValue={moment(Date.now()).format('YYYY-MM-DD')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setStartDate(data.target.value)}
                  />
                </div>
                {eventType.eventType === 'harvest' ? (
                  <div className={classes.yieldOuter}>
                    <ValidationTextField
                      id="setPlantDarkDays"
                      label="Gram"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => setEventYield(data.target.value)}
                      helperText={'Skördevikt'}
                      variant="outlined"
                    />
                  </div>
                ) : (
                  null
                )}
              </div>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <SplashScreen/>
            ) : (
              <Button onClick={handleAddEvent} color="primary" autoFocus>
                Lägg till
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          open={openEdit}
          onClose={handleCloseEdit}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Uppdatera händelse:"}</DialogTitle>
          <DialogContent>
            <div className={classes.textFieldOuterSelect}>
              <div className={classes.switchOuter}>
                {props.plantItem.plantCategory === 'Mikrogrönt' ? (
                  <ValidationTextField
                    id="standard-select-category"
                    select
                    label="Välj"
                    onChange={handleCategoryChange}
                    helperText="Kategori"
                    fullWidth
                    variant="outlined"
                  >
                    {eventTypesMicro.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </ValidationTextField>
                ) : (
                  <ValidationTextField
                    id="standard-select-category"
                    select
                    label="Välj"
                    onChange={handleCategoryChange}
                    helperText="Kategori"
                    fullWidth
                    variant="outlined"
                  >
                    {eventTypesLettuce.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </ValidationTextField>
                )}
              </div>
              <div className={classes.dateOuter}>
                <ValidationTextField
                  id="date"
                  label="Startdatum"
                  type="date"
                  defaultValue={moment(startDate).format('YYYY-MM-DD')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(data) => setStartDate(data.target.value)}
                />
              </div>
              {eventType.eventType === 'harvest' ? (
                <div className={classes.yieldOuter}>
                  <ValidationTextField
                    id="setPlantDarkDays"
                    label="Gram"
                    type="number"
                    value={eventYield}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(data) => setEventYield(data.target.value)}
                    helperText={'Skördevikt'}
                    variant="outlined"
                  />
                </div>
              ) : (
                null
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {loading ? (
              <SplashScreen/>
            ) : (
              <div>
                <Button onClick={handleDeleteEvent} color="primary" autoFocus>
                  Radera
                </Button>
                <Button onClick={handleUpdateEvent} color="primary" autoFocus>
                  Uppdatera
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      </div>
    ) : (
      null
    )
  );
}
