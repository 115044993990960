import React, {useState, useEffect, PureComponent} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useFirebase, useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Funnel,
  FunnelChart
} from 'recharts';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import SplashScreen from '../../ui/SplashScreen'
import EventInfo from './EventInfo'
import ActiveEvents from './ActiveEvents'
import '../../../styles/Plants.css'


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div >
        <p>{`${payload[0].payload.name} : ${payload[0].payload.days} dagar`}</p>
        {payload[0].payload.eventYield > 0 ? (
          <p>{`Senaste skörd : ${payload[0].payload.eventYield}g`}</p>
        ) : (
          null
        )}
      </div>
    );
  }

  return null;
};

const EventsBarAlt = (props) => {

  let routeParams = useParams();
  let history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const classes = useStyles();
  useFirestoreConnect({
    collection: `plants/${props.plantItem.plantID}/plantItems/${props.eventItem.itemID}/events`,
    storeAs: "events" + props.eventItem.itemID,
  });
  let temoEvents = "events" + props.eventItem.itemID
  const events = useSelector((state) => state.firestore.data["events" + props.eventItem.itemID]);

  const [eventsArr, setEventsArr] = useState(null);
  const [barArr, setBarArr] = useState(null);

  const calculateBar = (eventItems) => {

    let eventItemsTemp = [];
    let tempArray = [];

    for (let i = 0; i < eventItems.length; i++) {
      eventItemsTemp.push(eventItems[i][1])
    }

    eventItemsTemp.sort(function(a,b){
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.startDate) - new Date(b.startDate);
    });

    for (let i = 0; i < eventItemsTemp.length; i++) {
      let tempObj = {
        name: eventItemsTemp[i].eventType.name,
        days: 0,
        fill: '#fff',
        date: eventItemsTemp[i].startDate,
        parentID: props.eventItem.itemID,
        eventYield: eventItemsTemp[i].eventYield,
      };

      if(i === 0){
        if (Number(moment(props.eventItem.startDate).startOf('day')) !== Number(moment(eventItemsTemp[0].startDate).startOf('day'))) {
          tempArray.push({
            name: 'Ospecificerat',
            days: moment(eventItemsTemp[0].startDate).startOf('day').diff(moment(props.eventItem.startDate).startOf('day'), 'days'),
            fill: '#9b9b9b',
            date: Date.now(),
            parentID: props.eventItem.itemID,
            eventYield: eventItemsTemp[i].eventYield,
          })
        }
      }

      if(i === (eventItemsTemp.length - 1)){
        tempObj.days = moment(Date.now()).diff(moment(eventItemsTemp[i].startDate), 'days')
      } else {
        tempObj.days = moment(eventItemsTemp[(i + 1)].startDate).diff(moment(eventItemsTemp[i].startDate), 'days')
      }

      switch(eventItemsTemp[i].eventType.eventType) {
        case 'dark':
          tempObj.fill = '#575757';
          break;
        case 'sprout':
          tempObj.fill = '#7edc88';
          break;
        case 'light':
          tempObj.fill = '#c4c30d';
          break;
        case 'harvest':
          tempObj.fill = '#168b77';
          break;
        default:
          tempObj.fill = '#959595';
      }
      tempArray.push(tempObj)
    }

    let tempDate;

    switch(props.plantItem.plantCategory) {
      case 'Örter/Kryddor':
        tempDate = moment(props.eventItem.startDate).startOf('day').add(Number(props.plantItem.herbSpecifics.daysUntilFirstHarvestHerb), 'days')
        break;
      case 'Mikrogrönt':
        tempDate = moment(props.eventItem.startDate).startOf('day').add(Number(props.plantItem.microSpecifics.plantDays), 'days')
        break;
      default:
        tempDate = moment(props.eventItem.startDate).startOf('day').add(Number(props.plantItem.lettuceSpecifics.daysUntilFirstHarvest), 'days')
    }

    if(eventItemsTemp[eventItemsTemp.length - 1].eventType.eventType === 'harvest' ){

      let dateToHarvest;

      if (props.plantItem.plantCategory === 'Örter/Kryddor') {
        dateToHarvest = moment(eventItemsTemp[eventItemsTemp.length - 1].startDate).startOf('day').add(Number(props.plantItem.herbSpecifics.harvestWindowHerb), 'days');
      } else if (props.plantItem.plantCategory === 'Mikrogrönt') {
        dateToHarvest = moment(eventItemsTemp[eventItemsTemp.length - 1].startDate).startOf('day').add(Number(props.plantItem.microSpecifics.plantDays), 'days');
      } else {
        dateToHarvest = moment(eventItemsTemp[eventItemsTemp.length - 1].startDate).startOf('day').add(Number(props.plantItem.lettuceSpecifics.harvestWindow), 'days');
      }

      tempArray.push({
        name: 'Till skörd',
        days: Number(dateToHarvest.startOf('day').diff(moment().startOf('day'), 'days')),
        fill: '#9b9b9b',
        date: Date.now(),
        parentID: props.eventItem.itemID,
        eventYield: 0,
      })
    } else {
      if (tempDate.isAfter(moment(tempArray[tempArray.length - 1].date))) {
        tempArray.push({
          name: 'Till skörd',
          days: Number(tempDate.startOf('day').diff(moment().startOf('day'), 'days')),
          fill: '#9b9b9b',
          date: Date.now(),
          parentID: props.eventItem.itemID,
          eventYield: 0,
        })
      }
    }

    setBarArr(tempArray)
    setEventsArr(tempArray)
  }

  useEffect(() => {
    if (events) {
      calculateBar(Object.entries(events))
    }

  }, [events, props.plantItem.plantCategory]);

  return (
    events && props.eventItem && props.plantItem && barArr && barArr.length > 0 && barArr && barArr.length > 0 ? (
      <div className='EventsBar'>
        <ResponsiveContainer width="100%" height="100%">
          <FunnelChart margin={{
          top: 5, right: 5, bottom: 5, left: 5
          }}>
            <Tooltip content={<CustomTooltip />} />
                <Funnel
                  dataKey='days'
                  data={barArr}
                  isAnimationActive
                />
          </FunnelChart>
        </ResponsiveContainer>
      </div>
    ) : (
      null
    )
  );
};
export default EventsBarAlt;
