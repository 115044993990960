import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import '../../../styles/Plants.css'
import {useFirestore} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 20,

  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    alignSelf: 'start',
    fontSize: '110%',
  },

  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'grey',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    zIndex: theme.zIndex.drawer + 11001,
    color: '#168b77',
    borderColor: '#168b77',
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

/**
 * @return {null}
 */
export default function PlaceMap(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [placeDisplayName, setPlaceDisplayName] = useState('');
  const [placeSubtitle, setPlaceSubtitle] = useState("");
  const [placeAddressName, setPlaceAddressName] = useState("");
  const [placeStreetAddress, setPlaceStreetAddress] = useState("");
  const [placeCity, setPlaceCity] = useState('');
  const [placePostNumber, setPlacePostNumber] = useState('');
  const [placeCordinates, setPlaceCordinates] = useState('');

  useEffect(() => {
    if (props.placeItem) {
      setPlaceDisplayName(props.placeItem.placeDisplayName)
      setPlaceSubtitle(props.placeItem.placeSubtitle)
      setPlaceAddressName(props.placeItem.placeAddressName)
      setPlaceStreetAddress(props.placeItem.placeStreetAddress)
      setPlaceCity(props.placeItem.placeCity)
      setPlacePostNumber(props.placeItem.placePostNumber)
      setPlaceCordinates(props.placeItem.placeCordinates)
    }
  }, [props.placeItem]);

  return (
    placeCordinates !== '' ? (
      <div className='InfoItem'>
        <Accordion className={classes.root}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Karta</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionInner}>
            <iframe
              className={'MapsElement'}
              src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCVCOdSlc7E6mjBRxzZACKF4UxGZz3Z8Ws&q=' + placeCordinates}>
            </iframe>
          </AccordionDetails>
        </Accordion>
      </div>
      ) : (
        null
      )
  );
}
