import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import '../../../styles/Plants.css'
import {useFirestore} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 20,

  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    alignSelf: 'start',
    fontSize: '100%',
  },

  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'grey',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    flexDirection: 'row'
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'column',
    width: '50%',
  },
  checkOuterSecond: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: -15,
    flexDirection: 'row',
    width: '100%',
  },
  formControl: {
    margin: 0
  },
  checkBox: {
    color: '#168b77',
    margin: 0
  },
  textDivOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    textAlign: 'left',
  },
  textDivInner: {
    width: '46%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  '@media screen and (max-width: 500px)': {
    textDivOuter: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    textDivInner: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

export default function PlaceInfo(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [placeName, setPlaceName] = useState('');
  const [placeDisplayName, setPlaceDisplayName] = useState('');
  const [placeSubtitle, setPlaceSubtitle] = useState("");
  const [placeInfoOne, setPlaceInfoOne] = useState('');
  const [placeInfoTwo, setPlaceInfoTwo] = useState("");
  const [placeAddressName, setPlaceAddressName] = useState("");
  const [placeStreetAddress, setPlaceStreetAddress] = useState("");
  const [placeCity, setPlaceCity] = useState('');
  const [placePostNumber, setPlacePostNumber] = useState('');
  const [placeCordinates, setPlaceCordinates] = useState('');

  const [privateCustomer, setPrivateCustomer] = useState(false);
  const [companyCustomer, setCompanyCustomer] = useState(false);

  const [placeActive, setPlaceActive] = useState(false);

  const handleSettingClick = () => {
    setOpenEdit(!openEdit);
  };

  const handleUpdateInfo = () => {
    setLoading(true)
    firestore
      .collection('places')
      .doc(props.placeItem.placeID)
      .update({
        placeName: placeName,
        placeDisplayName: placeDisplayName,
        placeSubtitle: placeSubtitle,
        placeInfoOne: placeInfoOne,
        placeInfoTwo: placeInfoTwo,
        placeAddressName: placeAddressName,
        placeStreetAddress: placeStreetAddress,
        placeCity: placeCity,
        placePostNumber: placePostNumber,
        placeCordinates: placeCordinates,
        updatedAt: Date.now(),
        updatedBy: uid,
        private: privateCustomer,
        company: companyCustomer,
        placeActive: placeActive,
      }).then(() =>{
      setOpenEdit(!openEdit);
      setLoading(false)
      dispatch(showSuccessSnackbar("Info i " + placeName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (props.placeItem) {
      setPlaceName(props.placeItem.placeName)
      setPlaceDisplayName(props.placeItem.placeDisplayName)
      setPlaceSubtitle(props.placeItem.placeSubtitle)
      setPlaceInfoOne(props.placeItem.placeInfoOne)
      setPlaceInfoTwo(props.placeItem.placeInfoTwo)
      setPlaceAddressName(props.placeItem.placeAddressName)
      setPlaceStreetAddress(props.placeItem.placeStreetAddress)
      setPlaceCity(props.placeItem.placeCity)
      setPlacePostNumber(props.placeItem.placePostNumber)
      setPlaceCordinates(props.placeItem.placeCordinates)
      setPrivateCustomer(props.placeItem.private)
      setCompanyCustomer(props.placeItem.company)
      setPlaceActive(props.placeItem.placeActive)

    }
  }, [props.placeItem]);

  return (
    <div className='InfoItem'>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Info</Typography>
        </AccordionSummary>
        {openEdit ? (
          <AccordionDetails className={classes.accordionInner} >
            <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
              <MoreVertIcon/>
            </IconButton>
            <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantName"
                    label="Namn"
                    variant="outlined"
                    onChange={(data) => setPlaceName(data.target.value)}
                    fullWidth
                    value={placeName}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantDisplayName"
                    label="Visningsnamn"
                    variant="outlined"
                    onChange={(data) => setPlaceDisplayName(data.target.value)}
                    fullWidth
                    value={placeDisplayName}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantInfoOne"
                    label="Inforuta #1"
                    variant="outlined"
                    onChange={(data) => setPlaceInfoOne(data.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    value={placeInfoOne}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantInfoTwo"
                    label="Inforuta #2"
                    variant="outlined"
                    onChange={(data) => setPlaceInfoTwo(data.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    value={placeInfoTwo}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantSubtitle"
                    label="Undertitel"
                    variant="outlined"
                    onChange={(data) => setPlaceSubtitle(data.target.value)}
                    fullWidth
                    value={placeSubtitle}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantName"
                    label="Platsnamn"
                    variant="outlined"
                    onChange={(data) => setPlaceAddressName(data.target.value)}
                    fullWidth
                    value={placeAddressName}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantDisplayName"
                    label="Adress"
                    variant="outlined"
                    onChange={(data) => setPlaceStreetAddress(data.target.value)}
                    fullWidth
                    value={placeStreetAddress}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantName"
                    label="Stad"
                    variant="outlined"
                    onChange={(data) => setPlaceCity(data.target.value)}
                    fullWidth
                    value={placeCity}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantDisplayName"
                    label="GPS-kordinater"
                    variant="outlined"
                    onChange={(data) => setPlaceCordinates(data.target.value)}
                    fullWidth
                    value={placeCordinates}
                  />
                </div>
                <div className={classes.switchOuter}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="default"
                        checked={placeActive}
                        onChange={() => setPlaceActive(!placeActive)}
                        name="setPlantShop"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label={placeActive ? 'Publicerad' : 'Opublicerad'}
                  />
                  <div className={classes.checkOuter}>
                    <FormControlLabel
                      classes={{root: classes.formControl}}
                      control={
                        <Checkbox
                          classes={{root: classes.checkBox, checked: classes.checkBox}}
                          checked={privateCustomer}
                          onChange={() => setPrivateCustomer(!privateCustomer)}
                          name="Privat"
                          color="default"
                        />
                      }
                      label="Privat"
                    />
                    <FormControlLabel
                      classes={{root: classes.formControl}}
                      control={
                        <Checkbox
                          classes={{root: classes.checkBox, checked: classes.checkBox}}
                          checked={companyCustomer}
                          onChange={() => setCompanyCustomer(!companyCustomer)}
                          name="Företag"
                          color="default"
                        />
                      }
                      label="Företag"
                    />
                  </div>
                </div>
              </div>
              <div className={classes.textFieldOuter}>
                {loading ? (
                  <SplashScreen/>
                ) : (
                  <Button classes={{root: classes.button}} variant="outlined" onClick={handleUpdateInfo} color="primary">
                    Spara
                  </Button>
                )}
              </div>
            </form>
          </AccordionDetails>
        ) : (
          <AccordionDetails className={classes.accordionInner}>
            <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
              <MoreVertIcon/>
            </IconButton>
            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Namn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeName}</Typography>
              </div>
              <div className={classes.textDivInner}>

              </div>
            </div>

            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Visningsnamn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeDisplayName}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Undertitel:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeSubtitle}</Typography>
              </div>
            </div>

            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Inforuta #1:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeInfoOne}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Inforuta #2:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeInfoTwo}</Typography>
              </div>
            </div>

            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Platsnamn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeAddressName}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Adress:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeStreetAddress}</Typography>
              </div>
            </div>

            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Stad:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeCity}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>GPS-kordinater:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{placeCordinates}</Typography>
              </div>
            </div>

            <div className={classes.checkOuterSecond}>
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={privateCustomer}
                    disabled={true}
                    name="Privat"
                    color="default"
                  />
                }
                label="Privat"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    classes={{root: classes.checkBox, checked: classes.checkBox}}
                    checked={companyCustomer}
                    disabled={true}
                    name="Företag"
                    color="default"
                  />
                }
                label="Företag"
              />
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
