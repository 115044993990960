import React from 'react';
import PrivateRoutes from "./component/PrivateRoutes";
import Dashboard from "./component/routes/Dashboard";
import SignIn from "./component/routes/SignIn";
import PlantItem from './component/plants/plantItems/PlantItem'
import EventPage from './component/plants/plantEvents/EventPage'
import PlaceItem from './component/delivery/placeItem/PlaceItem'
import {Switch, Route} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

import './styles/App.css'
import {useSelector} from "react-redux";

let innerHeight = require('ios-inner-height');

const useStyles = makeStyles((theme) => ({
  app: {
    textAlign: 'center',
    width: '100%',
    height: innerHeight(),
  }
}));

function App() {
  const stateObj = useSelector(state => state);
  const classes = useStyles();

  return (
    <div className={classes.app}>

      <Switch>
        <PrivateRoutes  path="/places/:placeID">
          <PlaceItem />
        </PrivateRoutes>
        <PrivateRoutes  path="/events/:eventID">
          <EventPage />
        </PrivateRoutes>
        <PrivateRoutes  path="/plants/:plantID">
          <PlantItem />
        </PrivateRoutes>
        <PrivateRoutes  path="/plants">
          <Dashboard />
        </PrivateRoutes>
        <PrivateRoutes  path="/orders">
          <Dashboard />
        </PrivateRoutes>
        <PrivateRoutes  path="/tasks">
          <Dashboard />
        </PrivateRoutes>
        <PrivateRoutes  path="/stock">
          <Dashboard />
        </PrivateRoutes>
        <PrivateRoutes  path="/places">
          <Dashboard />
        </PrivateRoutes>
        <Route path="/login">
          <SignIn />
        </Route>
        <PrivateRoutes  path="/">
          <Dashboard />
        </PrivateRoutes>
      </Switch>

    </div>
  );
}
export default App;
