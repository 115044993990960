import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useFirebase, useFirestoreConnect, useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import SplashScreen from '../../ui/SplashScreen'
import EventInfo from './EventInfo'
import ActiveEvents from './ActiveEvents'
import PlaceEventActive from './PlantEventActive'
import '../../../styles/Plants.css'


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const EventPage = () => {

  let routeParams = useParams();
  let history = useHistory();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const classes = useStyles();
  useFirestoreConnect({
    collection: `plants`,
    storeAs: "plants",
  });
  const plants = useSelector((state) => state.firestore.data.plants);

  const [plantItem, setPlantItem] = useState(null);
  const [eventItem, setEventItem] = useState(null);

  useEffect(() => {
      let plantID;
      firestore.collectionGroup('plantItems')
        .where('itemID', '==', routeParams.eventID)
        .get()
        .then((itemObj) => {
          itemObj.forEach((doc) => {
            console.log(doc.data())
            plantID = doc.data().plantID
            setEventItem(doc.data())
          });
        })
        .then(() => {
          firestore.collection('plants')
            .where('plantID', '==', plantID)
            .get()
            .then((plantObj) => {
              plantObj.forEach((docOne) => {
                console.log(docOne.data())
                setPlantItem(docOne.data())
              });
            })
        })
        .catch((err) => console.log(err.message))

  }, [plants, routeParams.eventID, firestore]);

  return (
    plantItem && eventItem ? (
      <div className='EventPage'>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => history.goBack()} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {plantItem.plantName}
            </Typography>
          </Toolbar>
        </AppBar>
        <EventInfo plantItem={plantItem} eventItem={eventItem}/>
        <ActiveEvents plantItem={plantItem} plantEvent={eventItem}/>
        <PlaceEventActive plantItem={plantItem} eventItem={eventItem}/>
      </div>
    ) : (
      <SplashScreen/>
    )
  );
};
export default EventPage;
