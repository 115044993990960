import React, {useEffect, useState} from "react";
import { useFirebase } from "react-redux-firebase";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import PlantListItem from './PlantListItem'
import SplashScreen from '../ui/SplashScreen'

const useStyles = makeStyles((theme) => ({
  root: {

    maxWidth: 900,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  titleText: {
    color: '#168b77',
    alignSelf: 'flex-start',
    paddingLeft: 20,
  },
  accordionRoot: {
    width: '100%',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(22),
    fontWeight: theme.typography.fontWeightBold,
    color: '#168b77',
  },
}));

const PlantListTemplate = (props) => {
  const firebase = useFirebase();

  const [plantCollection, setPlantCollection] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setPlantCollection(props.plantCollection)
  }, [props.plantCollection]);

  return (
    plantCollection ? (
      <div className='PlantListTemplate'>
        <Accordion className={classes.accordionRoot}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.accordionHeading}>{props.plantCategory}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.root}>
            {plantCollection.map((plantItem, plantIndex) => {
              return <PlantListItem key={plantIndex} plantItem={plantItem}/>
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <SplashScreen/>
      )
  );
};
export default PlantListTemplate;
