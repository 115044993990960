import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import '../../../styles/Plants.css'
import {useFirestore} from "react-redux-firebase";
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";
import SplashScreen from '../../ui/SplashScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordionInner: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: 20,

  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },

  titleTextFirst: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  titleText: {
    color: '#818181',
    alignSelf: 'start',
    fontSize: '85%',
  },
  infoText: {
    color: '#242424',
    alignSelf: 'start',
    fontSize: '100%',
  },

  iconButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: 'grey',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
  },
  button: {
    minWidth: 130,
    color: '#168b77',
    borderColor: '#168b77',
  },
  textDivOuter: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    textAlign: 'left',
  },
  textDivInner: {
    width: '46%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  '@media screen and (max-width: 500px)': {
    textDivOuter: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    textDivInner: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

export default function InfoItem(props) {
  const classes = useStyles();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [plantName, setPlantName] = useState('');
  const [plantLatin, setPlantLatin] = useState("");
  const [plantDisplayName, setPlantDisplayName] = useState();
  const [plantSubtitle, setPlantSubtitle] = useState("");
  const [plantInfoOne, setPlantInfoOne] = useState('');
  const [plantInfoTwo, setPlantInfoTwo] = useState("");
  const [plantManufacturer, setPlantManufacturer] = useState("");

  const handleSettingClick = () => {
    setOpenEdit(!openEdit);
  };

  const handleUpdateInfo = () => {
    setLoading(true)
    firestore
      .collection('plants')
      .doc(props.plantItem.plantID)
      .update({
        plantName: plantName,
        plantLatin: plantLatin,
        plantDisplayName: plantDisplayName,
        plantSubtitle: plantSubtitle,
        plantInfoTwo: plantInfoTwo,
        plantInfoOne: plantInfoOne,
        plantManufacturer: plantManufacturer,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      setOpenEdit(!openEdit);
      setLoading(false)
      dispatch(showSuccessSnackbar("Info i " + plantName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (props.plantItem) {
      setPlantName(props.plantItem.plantName)
      setPlantLatin(props.plantItem.plantLatin)
      setPlantDisplayName(props.plantItem.plantDisplayName)
      setPlantSubtitle(props.plantItem.plantSubtitle)
      setPlantInfoOne(props.plantItem.plantInfoOne)
      setPlantInfoTwo(props.plantItem.plantInfoTwo)
      setPlantManufacturer(props.plantItem.plantManufacturer)
    }
  }, [props.plantItem]);

  return (
    <div className='InfoItem'>
      <Accordion className={classes.root}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Info</Typography>
        </AccordionSummary>
        {openEdit ? (
          <AccordionDetails className={classes.accordionInner} >
            <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
              <MoreVertIcon/>
            </IconButton>
            <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantName"
                    label="Namn"
                    variant="outlined"
                    onChange={(data) => setPlantName(data.target.value)}
                    fullWidth
                    value={plantName}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantDisplayName"
                    label="Visningsnamn"
                    variant="outlined"
                    onChange={(data) => setPlantDisplayName(data.target.value)}
                    fullWidth
                    value={plantDisplayName}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantLatin"
                    label="Latinskt namn"
                    variant="outlined"
                    onChange={(data) => setPlantLatin(data.target.value)}
                    fullWidth
                    value={plantLatin}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantSubtitle"
                    label="Undertitel"
                    variant="outlined"
                    onChange={(data) => setPlantSubtitle(data.target.value)}
                    fullWidth
                    value={plantSubtitle}
                  />
                </div>
              </div>

              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantInfoOne"
                    label="Inforuta #1"
                    variant="outlined"
                    onChange={(data) => setPlantInfoOne(data.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    value={plantInfoOne}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantInfoTwo"
                    label="Inforuta #2"
                    variant="outlined"
                    onChange={(data) => setPlantInfoTwo(data.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    value={plantInfoTwo}
                  />
                </div>
              </div>
              <div className={classes.formInner}>
                <div className={classes.textFieldOuter}>
                  <ValidationTextField
                    id="setPlantManufacturer"
                    label="Tillverkare"
                    variant="outlined"
                    onChange={(data) => setPlantManufacturer(data.target.value)}
                    fullWidth
                    value={plantManufacturer}
                  />
                </div>
                <div className={classes.textFieldOuter}>
                  {loading ? (
                    <SplashScreen/>
                  ) : (
                    <Button classes={{root: classes.button}}variant="outlined" onClick={handleUpdateInfo} color="primary">
                      Spara
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </AccordionDetails>
        ) : (
          <AccordionDetails className={classes.accordionInner}>
            <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleSettingClick}>
              <MoreVertIcon/>
            </IconButton>
            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Namn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantName}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Latinskt namn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantLatin}</Typography>
              </div>
            </div>
            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Visningsnamn:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantDisplayName}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Undertitel:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantSubtitle}</Typography>
              </div>
            </div>
            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Inforuta #1:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInfoOne}</Typography>
              </div>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Inforuta #2:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantInfoTwo}</Typography>
              </div>
            </div>
            <div className={classes.textDivOuter}>
              <div className={classes.textDivInner}>
                <Typography className={classes.titleTextFirst} variant={'caption'}>Tillverkare:</Typography>
                <Typography className={classes.infoText} variant={'body1'} paragraph>{plantManufacturer}</Typography>
              </div>
              <div className={classes.textDivInner}>

              </div>
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
}
