import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import {useFirebase, useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {useDispatch, useSelector} from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SplashScreen from '../../ui/SplashScreen'

import '../../../styles/Plants.css'
import {showSuccessSnackbar} from "../../utils/actions/SnackbarActions";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },

}));

const PlaceActive = (props) => {

  let routeParams = useParams();
  let history = useHistory();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector((state) => state.firebase.auth);

  const [placeItem, setPlaceItem] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleActiveChange = () => {
    setLoading(true)
    firestore
      .collection('places')
      .doc(placeItem.placeID)
      .update({
        placeActive: !placeItem.placeActive,
        updatedAt: Date.now(),
        updatedBy: uid,
      }).then(() =>{
      setLoading(false)
      dispatch(showSuccessSnackbar(placeItem.placeName + ' uppdaterades!'));
    }).catch((err) => {
      console.log(err)
      setLoading(false)
      dispatch(showSuccessSnackbar("Något gick fel. Var god försök igen!"));
    });
  };

  useEffect(() => {
    if (props.placeItem) {
      setPlaceItem(props.placeItem)
    } else {
      setPlaceItem(null)
    }
  }, [props]);

  return (
    placeItem ? (
      <div className={classes.root}>
        {loading ? (
          <SplashScreen/>
        ) : (
          placeItem.placeActive ? (
            <Button onClick={handleActiveChange} color="primary" >
              Avaktivera
            </Button>
          ) : (
            <Button onClick={handleActiveChange} color="primary" >
              Aktivera
            </Button>
          )
        )}
      </div>
    ) : (
      null
    )
  );
};
export default PlaceActive;
