import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useSelector } from "react-redux";
import SplashScreen from './ui/SplashScreen';

const PrivateRoutes = ({ children, ...remainingProps }) => {
  const auth = useSelector(state => state.firebase.auth);
  return (
    isLoaded(auth) ? (
      <Route
        {...remainingProps}
        render={({ location }) =>
          !isEmpty(auth) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    ) : (
      <SplashScreen/>
      )
  );
};
export default PrivateRoutes;
