import React, {useEffect, useState} from "react";
import {useFirestore, useFirestoreConnect} from "react-redux-firebase";
import {
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '@material-ui/core/Backdrop';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import {showSuccessSnackbar} from "../utils/actions/SnackbarActions";

import SplashScreen from '../ui/SplashScreen';

import '../../styles/Dashboard.css'
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  saveButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: 'white',
    zIndex: 10000,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40,
    maxWidth: 900,
  },
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

  },
  formInner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%'
  },
  textField: {
    borderColor: '#168b77',
  },
  textFieldOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  textFieldOuterImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderRadius: 5
  },
  textFieldOuterImageEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#dcdbe1',
    minHeight: 50,
    borderRadius: 5
  },
  imageStyle: {
    width: '25%'
  },
  divImageStyle: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: '80%'
  },
  imageDiv: {
    width: '25%',
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#959595',
  },
  imageDivText: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '80%'
  },
  pickerOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '45%',
    paddingBottom: 30,
  },
  switchOuter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30%',
    flexDirection: 'column'
  },
  checkOuter: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '40%',
  },
  '@media screen and (max-width: 600px)': {
    formInner: {
      flexDirection: 'column',
    },
    textFieldOuter: {
      width: '90%',
    },
    pickerOuter: {
      width: '90%',
    },
    textFieldOuterSelect: {
      width: '90%',
    },
  },
  specifics: {
    width: '100%'
  },
  iconButtonLink: {
    position: 'relative',
    right: 0,
    color: '#168b77',
    zIndex: 10000,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 11000,
    color: '#fff',
  },
  checkBox: {
    color: '#168b77',
    margin: 0
  },
  backdropButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 20
  },
  button: {
    minWidth: 130,
    zIndex: theme.zIndex.drawer + 11001,
  },
  formControl: {
    margin: 0
  }
}));

const ValidationTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#168b77',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#168b77',
      },
      '&.Mui-focused fieldset': {
        borderLeftWidth: 6,
        borderColor: '#168b77',
        padding: '4px !important', // override inline-style
      },
    },
    '& .MuiSelect-root': {
      color: '#168b77',
    },
  },
})(TextField);

// Plant settings
const plantCategories = ['Mikrogrönt', 'Sallad', 'Kål', 'Örter/Kryddor'];

const AddOrder = (props) => {

  const firestore = useFirestore();
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { uid } = useSelector((state) => state.firebase.auth);

  useFirestoreConnect({
    collection: `places`,
    storeAs: "places",
  });
  const places = useSelector((state) => state.firestore.data.places);

  useFirestoreConnect({
    collection: `plants`,
    storeAs: "plants",
  });
  const plants = useSelector((state) => state.firestore.data.plants);


  const [loading, setLoading] = useState(false);
  const [placesLoaded, setPlacesLoaded] = useState(false);
  const [plantsLoaded, setPlantsLoaded] = useState(false);

  const [orderName, setOrderName] = useState('');
  const [placeID, setPlaceID] = useState('');
  const [placeEventID, setPlaceEventID] = useState('');
  const [orderedItems, setOrderedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [customPrice, setCustomPrice] = useState(0);
  const [orderNumber, setOrderNumber] = useState(0);

  const [privateCustomer, setPrivateCustomer] = useState(true);

  const [placeArray, setPlaceArray] = useState(null);
  const [eventArray, setEventArray] = useState(null);
  const [placeChosen, setPlaceChosen] = useState('');
  const [eventChosen, setEventChosen] = useState('');

  const [plantArray, setPlantArray] = useState(null);
  const [weightArray, setWeightArray] = useState(null);
  const [plantChosen, setPlantChosen] = useState('');
  const [weightChosen, setWeightChosen] = useState('');

  const [orderPaid, setOrderPaid] = useState(false);
  const [orderDelivered, setOrderDelivered] = useState(false);

  const addNewPlace = () => {
    setLoading(true)
    firestore
      .collection("orders")
      .add({
        orderName: orderName,
        orderNumber: orderNumber,
        placeID: placeChosen.placeID,
        placeEventID: eventChosen.eventID,
        placeName: placeChosen.placeName,
        placeName: placeChosen.placeName,
        placeDisplayName: placeChosen.placeDisplayName,
        placeAddressName: placeChosen.placeAddressName,
        placeCordinates: placeChosen.placeCordinates,
        eventStart: eventChosen.startDate,
        eventEnd: eventChosen.endDate,
        orderedItems: orderedItems,
        totalPrice: customPrice !== 0 ? (customPrice) : (totalPrice),
        private: privateCustomer,
        orderPaid: orderPaid,
        orderDelivered: orderDelivered,
        updatedAt: Date.now(),
        updatedBy: uid,
        createdAt: Date.now(),
        author: uid,

      })
      .then((docRef) => {
        docRef.update({
          orderID: docRef.id,
        });
      })
      .then(() => {
      props.handleClose();
      dispatch(showSuccessSnackbar("Ordern lades till!"));
    }).catch((error) => {
      setLoading(false)
      console.log(error.message)
      dispatch(showSuccessSnackbar("Något gick fel! Var god försök igen."));
    });
  };

  const handleCategoryChangePlaces = (event) => {

    setEventArray(null);
    setPlaceChosen('');
    setEventChosen('');
    setWeightArray(null);
    setPlantChosen('');
    setWeightChosen('');
    let tempArr = []

    firestore
      .collection("places")
      .doc(event.target.value.placeID)
      .collection("events")
      .get()
      .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
          tempArr.push(doc.data())
        });
      })
      .then(() => {
      setEventArray(tempArr)
      setPlaceChosen(event.target.value);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleCategoryChangeEvents = (event) => {
    setEventChosen(event.target.value);
  };

  const handleCategoryChangePlants = (event) => {

    setWeightArray(null);
    setPlantChosen('');
    setWeightChosen('');

    let tempArr = []

    firestore
      .collection("plants")
      .doc(event.target.value.plantID)
      .collection("prices")
      .get()
      .then((querySnapshot) => {
      querySnapshot.forEach(function(doc) {
          tempArr.push(doc.data())
        });
      })
      .then(() => {
      setWeightArray(tempArr)
      setPlantChosen(event.target.value);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleCategoryChangeWeight = (event) => {
    setWeightChosen(event.target.value);
  };

  const addToCart = () => {
    let tempArray = [...orderedItems];
    for (let i = 0; i < tempArray.length; i++) {
      if (weightChosen.priceID === tempArray[i].priceID) {
        tempArray[i].amount = Number(tempArray[i].amount) + 1
        setOrderedItems(tempArray)
        setWeightArray(null);
        setPlantChosen('');
        return setWeightChosen('');
      }
    }
    let tempObj = {...weightChosen, amount: 1}
    tempArray.push(tempObj)
    setOrderedItems(tempArray)
    setWeightArray(null);
    setPlantChosen('');
    setWeightChosen('');
  };

  const handleAmountChange = (eventTarget, priceItem) => {
    console.log(eventTarget);
    console.log(priceItem);
    let tempArray = [...orderedItems];
    for (let i = 0; i < tempArray.length; i++) {
      if (priceItem.priceID === tempArray[i].priceID) {
        console.log(tempArray[i]);
        tempArray[i].amount = Number(eventTarget)
        setOrderedItems(tempArray)
        console.log(orderedItems);
      }
    }
  };

  const removeFromChart = (index) => {
    let tempArray = [...orderedItems];
    tempArray.splice(index, 1)
    setOrderedItems(tempArray)
  };

  useEffect(() => {
    let tempPrice = 0;
    for (let i = 0; i < orderedItems.length; i++) {
      tempPrice = Number(tempPrice) + (Number(orderedItems[i].price) * Number(orderedItems[i].amount))
    }
    setTotalPrice(tempPrice)
  }, [orderedItems]);

  useEffect(() => {
    if (places) {
      let tempArr = []
      let placesArray = Object.entries(places)
      console.log(placesArray)
      for (let i = 0; i < placesArray.length; i++) {
        tempArr.push(placesArray[i][1]);
      }
      setPlaceArray(tempArr)
      setPlacesLoaded(true)
    } else {
      setPlacesLoaded(true)
    }
    if (plants) {
      let tempArr = []
      let plantsArray = Object.entries(plants)
      console.log(plantsArray)
      for (let i = 0; i < plantsArray.length; i++) {
        tempArr.push(plantsArray[i][1]);
      }
      setPlantArray(tempArr)
      setPlantsLoaded(true)
    } else {
      setPlantsLoaded(true)
    }
  }, [places, plants]);

console.log(placeChosen);
console.log(eventArray);
console.log(eventChosen);
console.log(plantChosen);
console.log(weightArray);
console.log(weightChosen);
console.log(orderedItems);

  return (
    loading ? (
      <SplashScreen/>
    ) : (
      placesLoaded && plantsLoaded ? (
        placeArray && plantArray ? (
          <div className='AddPlant'>
            <Button
              className={classes.saveButton}
              autoFocus color="inherit"
              onClick={addNewPlace}
            >
              spara
            </Button>
            <div className={classes.formRoot}>
              <form className={classes.form} noValidate autoComplete="off">

                <div className={classes.formInner}>
                  <div className={classes.textFieldOuter}>
                    <ValidationTextField
                      id="Namn på beställare"
                      label="Namn"
                      variant="outlined"
                      onChange={(data) => setOrderName(data.target.value)}
                      fullWidth
                    />
                  </div>
                  <div className={classes.textFieldOuterSelect}>
                    <div className={classes.switchOuter}>
                      <ValidationTextField
                        id="setEstimatedYieldMicro"
                        label="Nr"
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(data) => setOrderNumber(data.target.value)}
                        variant="outlined"
                      />
                    </div>
                    <div className={classes.checkOuter}>
                      <FormControlLabel
                        classes={{root: classes.formControl}}
                        control={
                          <Checkbox
                            classes={{root: classes.checkBox, checked: classes.checkBox}}
                            checked={orderPaid}
                            onChange={() => setOrderPaid(!orderPaid)}
                            name="Betald"
                            color="default"
                          />
                        }
                        label="Betald"
                      />
                      <FormControlLabel
                        classes={{root: classes.formControl}}
                        control={
                          <Checkbox
                            classes={{root: classes.checkBox, checked: classes.checkBox}}
                            checked={orderDelivered}
                            onChange={() => setOrderDelivered(!orderDelivered)}
                            name="Levererad"
                            color="default"
                          />
                        }
                        label="Levererad"
                      />
                    </div>
                    <div className={classes.switchOuter}>
                      <FormControlLabel
                        labelPlacement={'bottom'}
                        control={
                          <Switch
                            color="default"
                            checked={privateCustomer}
                            onChange={() => setPrivateCustomer(!privateCustomer)}
                            name="setPlantShop"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label={privateCustomer ? 'Privat' : 'Företag'}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.formInner}>
                  <div className={classes.pickerOuter}>
                    <ValidationTextField
                      id="standard-select-category"
                      select
                      label="Välj"
                      value={placeChosen}
                      onChange={handleCategoryChangePlaces}
                      helperText="Utlämningsplats"
                      fullWidth
                      variant="outlined"
                    >
                      {placeArray.map((option) => (
                        <MenuItem key={option.placeName} value={option}>
                          {option.placeName}
                        </MenuItem>
                      ))}
                    </ValidationTextField>
                  </div>
                  {eventArray && eventArray.length > 0 ? (
                    <div className={classes.pickerOuter}>
                      <ValidationTextField
                        id="standard-select-category"
                        select
                        label="Välj"
                        value={eventChosen}
                        onChange={handleCategoryChangeEvents}
                        helperText="Datum"
                        fullWidth
                        variant="outlined"
                      >
                        {eventArray.map((option) => (
                          <MenuItem key={option.eventID} value={option}>
                            {moment(option.startDate).calendar() + ' - ' + moment(option.endDate).format("HH:mm")}
                          </MenuItem>
                        ))}
                      </ValidationTextField>
                    </div>
                  ) : (
                    <div className={classes.pickerOuter}>

                    </div>
                  )}
                </div>

                {eventChosen ? (
                  <div className={classes.formInner}>
                    <div className={classes.pickerOuter}>
                      <ValidationTextField
                        id="standard-select-category"
                        select
                        label="Välj"
                        value={plantChosen}
                        onChange={handleCategoryChangePlants}
                        helperText="Vara"
                        fullWidth
                        variant="outlined"
                      >
                        {plantArray.map((option) => (
                          <MenuItem key={option.plantID} value={option}>
                            {option.plantName}
                          </MenuItem>
                        ))}
                      </ValidationTextField>
                    </div>
                    {weightArray && weightArray.length > 0 ? (
                      <div className={classes.pickerOuter}>
                        <ValidationTextField
                          id="standard-select-category"
                          select
                          label="Välj"
                          value={weightChosen}
                          onChange={handleCategoryChangeWeight}
                          helperText="Vikt"
                          fullWidth
                          variant="outlined"
                        >
                          {weightArray.map((option) => (
                            <MenuItem key={option.priceID} value={option}>
                              {option.weight + 'gram - ' + option.price + 'kr'}
                            </MenuItem>
                          ))}
                        </ValidationTextField>
                        {weightChosen ? (
                          <IconButton
                            variant="outlined"
                            color="primary"
                            classes={{colorPrimary: classes.iconButtonLink}}
                            onClick={addToCart}
                          >
                            <AddIcon/>
                          </IconButton>
                        ) : (
                          null
                        )}
                      </div>
                    ) : (
                      <div className={classes.pickerOuter}>

                      </div>
                    )}
                  </div>
                ) : (
                  null
                )}

              </form>
            </div>
            {orderedItems && orderedItems.length > 0 ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell>Vara</TableCell>
                      <TableCell align="right">Vikt</TableCell>
                      <TableCell align="right">Pris</TableCell>
                      <TableCell align="right">Antal</TableCell>
                      <TableCell align="right">Totalpris</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderedItems.map((row, rowIndex) => (
                      <TableRow key={row.priceID}>
                        <TableCell
                          align="right"
                          padding='checkbox'
                        >
                          <IconButton
                            variant="outlined"
                            color="primary"
                            classes={{colorPrimary: classes.iconButtonLink}}
                            onClick={() => removeFromChart(rowIndex)}
                          >
                            <DeleteIcon/>
                          </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.plantDisplayName}
                        </TableCell>
                        <TableCell align="right">{row.weight}g</TableCell>
                        <TableCell align="right">{row.price}kr</TableCell>
                        <TableCell align="right">
                          <TextField
                            id="standard-number"
                            type="number"
                            defaultValue={row.amount}
                            onChange={(event) => handleAmountChange(event.target.value, row)}
                          />
                        </TableCell>
                        <TableCell align="right">{row.amount * Number(row.price)}kr</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell>Totalt</TableCell>
                      <TableCell align="right">{totalPrice}kr</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell>Manuellt totalpris</TableCell>
                      <TableCell align="right">
                        <TextField
                          id="standard-number"
                          type="number"
                          defaultValue={customPrice}
                          onChange={(event) => setCustomPrice(event.target.value)}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              null
            )}
          </div>
        ) : (
          <div className='AddPlant'>
            <Button classes={{text: classes.addPlantButton}} onClick={() => history.push('/places')}>
              Ni måste lägga till utlämningsplats innan ni kan skapa en order. Klicka här för att lägga till.
            </Button>
          </div>
        )
      ) : (
        <SplashScreen/>
        )
    )
  );
};
export default AddOrder;
