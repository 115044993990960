import React, {useState, useEffect} from "react";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import SplashScreen from '../ui/SplashScreen'
import AddPlant from './AddPlant'
import PlantListTemplate from './PlantListTemplate'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    position: 'fixed',
    bottom: -4,
    right: 0,
    color: 'white',
    zIndex: 10000,
  },
  addPlantButton: {
    color: '#168b77',
  },
  dialogContainer: {
  },
  activeButton: {
    color: '#168b77',
  },
  inActiveButton: {
    color: 'grey',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Plants = () => {
  const firebase = useFirebase();
  const classes = useStyles();
  const [openModal, setModal] = useState(false);
  const [plantCollection, setPlantCollection] = useState(null);
  const [plantsLoaded, setPlantLoaded] = useState(false);
  const [plantActive, setPlantActive] = useState(true);

  useFirestoreConnect({
    collection: `plants`,
    storeAs: "plants",
  });
  const plants = useSelector((state) => state.firestore.data.plants);

  const handleClickOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleButtonGroupActive = () => {
    setPlantActive(true);
  };

  const handleButtonGroupInActive = () => {
    setPlantActive(false);
  };

  useEffect(() => {
    console.log(plants)
    if (plants) {
      let tempCollection = {
        microgreens: [],
        lettuce: [],
        kale: [],
        herbs: [],
        microgreensOff: [],
        lettuceOff: [],
        kaleOff: [],
        herbsOff: [],
      };
      let plantArray = Object.entries(plants)
      for (let i = 0; i < plantArray.length; i++) {
        switch(plantArray[i][1].plantCategory) {
          case 'Sallad':
            if (plantArray[i][1].plantActive === true) {
              tempCollection.lettuce.push(plantArray[i][1]);
            } else {
              tempCollection.lettuceOff.push(plantArray[i][1]);
            }
            break;
          case 'Kål':
            if (plantArray[i][1].plantActive === true) {
              tempCollection.kale.push(plantArray[i][1]);
            } else {
              tempCollection.kaleOff.push(plantArray[i][1]);
            }
            break;
          case 'Mikrogrönt':
            if (plantArray[i][1].plantActive === true) {
              tempCollection.microgreens.push(plantArray[i][1]);
            } else {
              tempCollection.microgreensOff.push(plantArray[i][1]);
            }
            break;
          default:
            if (plantArray[i][1].plantActive === true) {
              tempCollection.herbs.push(plantArray[i][1]);
            } else {
              tempCollection.herbsOff.push(plantArray[i][1]);
            }
        }
      }
      setPlantCollection(tempCollection)
      setPlantLoaded(true)
    } else {
      setPlantLoaded(true)
    }
  }, [plants]);

  return (
    <div className='Overview'>
      {plantsLoaded ? (
        plantCollection ? (
          <div className='PlantsInner'>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              <Button
                classes={plantActive ? {root: classes.activeButton} : {root: classes.inActiveButton}}
                onClick={handleButtonGroupActive}
              >Aktiva</Button>
              <Button
                classes={plantActive ? {root: classes.inActiveButton} : {root: classes.activeButton}}
                onClick={handleButtonGroupInActive}
              >Inaktiva</Button>
            </ButtonGroup>
            {plantActive ? (
              <div>
                {plantCollection.microgreens.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Mikrogrönt'}
                    plantCollection={plantCollection.microgreens}
                  />
                ) : (
                  null
                )}
                {plantCollection.lettuce.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Sallad'}
                    plantCollection={plantCollection.lettuce}
                  />
                ) : (
                  null
                )}
                {plantCollection.kale.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Kål'}
                    plantCollection={plantCollection.kale}
                  />
                ) : (
                  null
                )}
                {plantCollection.herbs.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Örter/Kryddor'}
                    plantCollection={plantCollection.herbs}
                  />
                ) : (
                  null
                )}
              </div>
            ) : (
              <div>
                {plantCollection.microgreensOff.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Mikrogrönt'}
                    plantCollection={plantCollection.microgreensOff}
                  />
                ) : (
                  null
                )}
                {plantCollection.lettuceOff.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Sallad'}
                    plantCollection={plantCollection.lettuceOff}
                  />
                ) : (
                  null
                )}
                {plantCollection.kaleOff.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Kål'}
                    plantCollection={plantCollection.kaleOff}
                  />
                ) : (
                  null
                )}
                {plantCollection.herbsOff.length > 0 ? (
                  <PlantListTemplate
                    plantCategory={'Örter/Kryddor'}
                    plantCollection={plantCollection.herbsOff}
                  />
                ) : (
                  null
                )}
              </div>
            )}

          </div>
        ) : (
          <Button classes={{text: classes.addPlantButton}} onClick={handleClickOpen}>
            Det finns inga växter. Klicka här för att lägga till.
          </Button>
        )
      ) : (
        <SplashScreen/>
      )}

      <Dialog
        fullScreen
        open={openModal}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll='paper'
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Lägg till växt
            </Typography>
          </Toolbar>
        </AppBar>
        <AddPlant handleClose={handleClose}/>
      </Dialog>
      {openModal ? (
        null
      ) : (
        <IconButton variant="outlined" color="primary" classes={{colorPrimary: classes.iconButton}} onClick={handleClickOpen}>
          <AddIcon/>
        </IconButton>
      )}
    </div>
  );
};
export default Plants;
