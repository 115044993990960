import React, {useEffect, useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useFirebase } from "react-redux-firebase";
import '../../styles/Dashboard.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';

import Logo from "../../static/logo/VWebLogo.png";

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    width: '95%',
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardActionArea: {
    height: 200,
    width: '50%'
  },
  cardMedia: {
    height: 200,
    width: '50%'
  },
  cardMediaLogo: {
    height: 200,
    width: 140,
    margin: 'auto'
  },
}));

const DeliveryListItem = (props) => {
  const firebase = useFirebase();
  let { url } = useRouteMatch();
  let history = useHistory();

  const classes = useStyles();
  return (
    <Card
      classes={{root: classes.cardRoot}}
      variant='outlined'
      onClick={() => history.push(`/places/${props.placeItem.placeID}`)}
    >
      <CardActionArea classes={{root: classes.cardActionArea}}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {props.placeItem.placeDisplayName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {props.placeItem.placeSubtitle}
          </Typography>
        </CardContent>
      </CardActionArea>
      {props.placeItem.imageLink !== '' ? (
        <CardMedia
          classes={{root: classes.cardMedia}}
          image={props.placeItem.imageLink}
          title="Plant image"
        />
      ) : (
        <CardMedia
          classes={{root: classes.cardMediaLogo}}
          image={Logo}
          title="Plant image"
        />
      )}
    </Card>
  );
};
export default DeliveryListItem;
