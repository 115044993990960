import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import SplashScreen from '../../ui/SplashScreen'
import PlaceImage from './PlaceImage'
import PlaceInfo from './PlaceInfo'
import PlaceMap from './PlaceMap'
import PlaceEvents from '../placeEvents/PlaceEvents'
import PlaceActive from './PlaceActive'

import '../../../styles/Plants.css'
import CardMedia from "@material-ui/core/CardMedia";
import Logo from "../../../static/logo/VWebLogo.png";
import PlantImage from "../../plants/plantItems/PlantImage";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const PlaceItem = () => {

  let routeParams = useParams();
  let history = useHistory();
  const firebase = useFirebase();
  const classes = useStyles();
  useFirestoreConnect({
    collection: `places`,
    storeAs: "places",
  });
  const places = useSelector((state) => state.firestore.data.places);
  const [placeItem, setPlaceItem] = useState(null);

  useEffect(() => {
    console.log(places)
    if (places) {
      let placeArray = Object.entries(places)
      placeArray.forEach((place) => {
        if (place[1].placeID === routeParams.placeID) {
          return setPlaceItem(place[1])
        }
      })
    }
  }, [places, routeParams.placeID]);

  return (
    placeItem ? (
      <div className='PlantItem'>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => history.goBack()} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {placeItem.placeName}
            </Typography>
          </Toolbar>
        </AppBar>
        <PlaceImage placeItem={placeItem}/>
        <PlaceInfo placeItem={placeItem}/>
        <PlaceMap placeItem={placeItem}/>
        <PlaceEvents placeItem={placeItem}/>
        <PlaceActive placeItem={placeItem}/>
      </div>
    ) : (
      <SplashScreen/>
    )
  );
};
export default PlaceItem;
