import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slide from '@material-ui/core/Slide';
import AddIcon from '@material-ui/icons/Add';

import SplashScreen from '../../ui/SplashScreen'
import MicroItem from './MicrogreensItem'
import LettuceItem from './LettuceItem'
import HerbItem from './HerbItem'
import PlantEvents from '../plantEvents/PlantEvents'
import PlantImage from './PlantImage'
import InfoItem from './InfoItem'
import PlantCategory from './PlantCategory'
import ShopItem from './ShopItem'
import PlantActive from './PlantActive'
import '../../../styles/Plants.css'
import CardMedia from "@material-ui/core/CardMedia";
import Logo from "../../../static/logo/VWebLogo.png";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: '#168b77',
  },
  title: {
    marginLeft: theme.spacing(2),
  },
}));

const PlantItem = () => {

  let routeParams = useParams();
  let history = useHistory();
  const firebase = useFirebase();
  const classes = useStyles();
  useFirestoreConnect({
    collection: `plants`,
    storeAs: "plants",
  });
  const plants = useSelector((state) => state.firestore.data.plants);
  const [plantItem, setPlantItem] = useState(null);

  useEffect(() => {
    if (plants) {
      let plantArray = Object.entries(plants)
      plantArray.forEach((plant) => {
        if (plant[1].plantID === routeParams.plantID) {
          return setPlantItem(plant[1])
        }
      })
    }
  }, [plants, routeParams.plantID]);

  return (
    plantItem ? (
      <div className='PlantItem'>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => history.goBack()} aria-label="close">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {plantItem.plantName}
            </Typography>
          </Toolbar>
        </AppBar>
        <PlantImage plantItem={plantItem}/>
        <InfoItem plantItem={plantItem}/>
        <PlantCategory plantItem={plantItem}/>
        <ShopItem plantItem={plantItem}/>
        <PlantEvents plantItem={plantItem}/>
        <PlantActive plantItem={plantItem}/>
      </div>
    ) : (
      <SplashScreen/>
      )
  );
};
export default PlantItem;
